import { Chip } from "@mui/material";
import { TObject, dcolors } from "corexxx";
import React from "react";
import { DWebTS } from "../common_ts/DWebTS";
import unplugged from "../img/unplugged.png";
import { DBox } from "./DBox";
import { DCol } from "./DCol";
import { DLink } from "./DLink";
import { DResponsiveWindow } from "./DResponsiveWindow";
import { DRow } from "./DRow";
export class DErrorBoundary extends React.Component {
    state: Readonly<TObject>;
    props: any;
    constructor(props: any) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }
    componentDidCatch(error: any, errorInfo: any) {
        // Catch errors in any components below and re-render with error message
        console.error(error)
        this.setState({
            error: error,
            errorInfo: errorInfo,
        });
        DWebTS.danalytics.report_fetal(error);
        // You can also log error messages to an error reporting service here
    }

    render() {
        if (this.state.errorInfo as any) {
            // Error path
            return (
                <DResponsiveWindow>
                    <DCol style={{ marginTop: 50 }}>
                        <DBox style={{ paddingLeft: 0, paddingRight: 0, maxWidth: 600, margin: "0 auto", alignItems: "center", overflow: "hidden" }}>
                            <img src={unplugged} style={{ width: "100%", objectFit: "contain" }} />
                            <p style={{ textAlign: "center", marginTop: 40 }}>
                                Something went wrong
                            </p>
                            <p style={{ textAlign: "center", marginTop: 10 }}>
                                {this.state.error.toString()}
                            </p>
                            <p style={{ marginTop: 10, textAlign: 'left', fontFamily: 'monospace', fontSize: 8, background: dcolors.pallets.yellow400, padding: 10 }}
                            >
                                {this.state.error.stack}
                            </p>
                            <DRow>
                                <Chip
                                    style={{ background: dcolors.pallets.red400, color: "white", width: 150, marginTop: 30, marginRight: 20 }}
                                    label={<DLink href="/">Back to Home</DLink>}
                                ></Chip>
                                <Chip
                                    style={{ background: dcolors.pallets.green400, color: "white", width: 150, marginTop: 30 }}
                                    label={<DLink href={"https://www.facebook.com/profile.php?id=100087654423509"}>Report this issue</DLink>}
                                ></Chip>
                            </DRow>
                        </DBox>
                    </DCol>
                </DResponsiveWindow>
            );
        }
        // Normally, just render children
        return this.props.children;
    }
}