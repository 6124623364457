import { TObject } from "corexxx";
import { DCol } from "./DCol";

// Show object as a table like a key vlaue pair
export const DTextObject = ({ obj }: { obj: TObject }) => {
    return (
        <DCol style={{ marginBottom: 8 }}>
            <table style={{ border: '1px solid black', borderCollapse: 'collapse' }}>
                <tr>
                    <td>Key</td>
                    <td>Value</td>
                </tr>
                {Object.keys(obj).map(k => <tr style={{ borderBottom: '1px solid black' }}>
                    <td style={{ padding: 3, border: '1px solid black' }}>{k}</td>
                    <td style={{ padding: 3, border: '1px solid black' }}>{obj[k]}</td>
                </tr>)}
            </table>
        </DCol>
    );
};