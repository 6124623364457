import { TObject } from "corexxx"
import { useState } from "react"
import { useDAppCommand } from "../../libs/dweb/DAppCommand"
import { DButton } from "../../libs/dweb/DButton"
import { DCodeDisplay } from "../../libs/dweb/DCodeDisplay"
import { DCol } from "../../libs/dweb/DCol"
import { DRow } from "../../libs/dweb/DRow"
import { DSearchButton } from "../../libs/dweb/DSearchButton"
import { DSelectInput } from "../../libs/dweb/DSelectInput"
import { BasePageNavigation } from "../App"
import { useAdmin } from "../Hook"

export function RemoteShell() {
    let admin = useAdmin()
    let app = useDAppCommand()
    let [output, setOutput] = useState<TObject>({})
    let [preset, setPreset] = useState<string>('git_pull')
    return (<BasePageNavigation>
        <DCol>
            <DRow style={{ flex: 0 }}>
                <DSearchButton onSearch={async (text) => {
                    let data = await app.api.getSimpleStore(app.state.simpleStoreEndpoint + '/api/utils/bash?cmd=' + text)
                    setOutput(data.out)
                }}
                    style={{ flex: 1, marginRight: 40 }}
                    btn_text="Run"
                />
                <DSelectInput value={preset} onChange={setPreset} options={[
                    { text: 'git_pull', value: 'git_pull' },
                    { text: 'disk', value: 'disk' },
                    { text: 'cpu', value: 'top' },
                    { text: 'upgrade_server', value: 'upgrade_server' },
                    { text: 'pm2_restart', value: 'pm2_restart' },
                    { text: 'git_pull', value: 'git_pull' }
                ]} />
                <DButton style={{ borderRadius: 0 }} text="Run Preset" onClick={async () => {
                    let data = await app.api.getSimpleStore(app.state.simpleStoreEndpoint + '/api/utils/bash?preset=' + preset)
                    setOutput(data.out)
                }}></DButton>
            </DRow>

            <DCodeDisplay code={(output?.stdout || 'stdout will be shown here') + '\n=====\n' + (output?.stderr || 'stderr will be shown here')} style={{ marginTop: 20 }} />
        </DCol>
    </BasePageNavigation>)
}