
// @ts-ignore
// @ts-nocheck

import { SimpleStoreClient } from "./SimpleStoreClient";

// This will override all sysmtel related overrides
export function overrideSystemApi() {

    (function () {
        var _log = console.log;
        var _error = console.error;
        var _warning = console.warn;

        console.error = function (errMessage) {
            //ImaginarySendMailErrorMethod(errMessage);// Send a mail with the error description
            SimpleStoreClient.Get().reportCrash(errMessage, arguments[3])
            _error.apply(console, arguments);
        };

        console.log = function (logMessage) {
            // Do something with the log message
            _log.apply(console, arguments);
        };

        console.warn = function (warnMessage) {
            // do something with the warn message
            _warning.apply(console, arguments);
        };

    })();
}
