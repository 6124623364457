import { CSSProperties } from 'react';
import { DAlert } from "./DAlert";
import { useDAppCommand } from "./DAppCommand";

export function DPageLoadingWrapper({ children, style, stickyNotification }: { children: any, style?: CSSProperties, stickyNotification?: boolean }) {
    let appCommmand = useDAppCommand()
    return (<div style={style}>

        {appCommmand.state.notification && !stickyNotification ? (
            <DAlert style={{ marginBottom: 10 }} notification={appCommmand.state.notification} />) : null}
        {children}
        {appCommmand.state.notification && stickyNotification ? (
            <DAlert style={{ position: 'absolute', bottom: 10, marginTop: 10, maxWidth: 400, right: 10 }} notification={appCommmand.state.notification} />) : null}
    </div>)
}