import CloseIcon from '@mui/icons-material/Close';
import { Dialog, IconButton } from "@mui/material";
import { TVoidCalBack } from "corexxx";
import { useDAppCommand } from "./DAppCommand";
import { DCol } from "./DCol";
import { DRow } from "./DRow";
import { DText } from "./DText";

export function DDialogSimple({ dialog_title, open, onClose, children }: { dialog_title: string, onClose: TVoidCalBack, open: boolean, children: any }) {
    let appCommand = useDAppCommand();
    return <Dialog open={open} onClose={onClose}>
        <DCol style={{ position: 'relative', width: 500 }}>
            <DRow style={{ background: appCommand.state.appConfig?.primary_color || 'black', padding: 10, alignItems: 'center' }}>
                <DText style={{ color: 'white', flex: 1 }}>{dialog_title}</DText>
                <IconButton
                    onClick={onClose}>
                    <CloseIcon style={{ color: 'white' }} />
                </IconButton>
            </DRow>
            {children}
        </DCol>
    </Dialog>
}