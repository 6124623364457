// Let's create a more concise implementation with a parent component (DAlternativeScreenHolder) that will handle 
// switching between screens based on the activescreen prop,
// and child components (DAlternativeScreen) that represent each screen

import React from "react";

export function DAlternativeScreenHolder({ activescreen, children }: { activescreen: string, children: any }) {
    const activeScreen = React.Children.toArray(children).find(
        (child: any) => child.props.name === activescreen
    );
    return <>{activeScreen}</>;
};

export function DAlternativeScreen({ children, name }: { children: any, name: string }) {
    return <>{children}</>;
};