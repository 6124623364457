export const DAbsoluteDiv = () => {
    return <div style={{
        "position": "absolute",
        "top": 0,
        "left": 0,
        "right": 0,
        "border": 0,
        "opacity": 0.9,
        "width": "100%",
        "height": "100%"
    }} />
}
