import { TStringCallback, TVoidCalBack } from "corexxx";
import { CSSProperties, useState } from "react";
import { DButton } from "./DButton";

export function DSearchButton({ placeholder, onSearch, inputStyle, style, btn_text, value, onTextChange, onBtnClick }:
    { inputStyle?: CSSProperties, placeholder?: string, onBtnClick?: TVoidCalBack, onTextChange?: TStringCallback, value?: string, onSearch?: TStringCallback, style?: CSSProperties, btn_text?: string }) {
    const [searchText, setSearchText] = useState(value || '');
    const handleSearch = () => {
        // Pass the search text to the parent component or perform the search logic here
        onSearch?.(searchText);
        onBtnClick?.()
    };

    const handleInputChange = (event: any) => {
        setSearchText(event.target.value);
        onTextChange?.(event.target.value)
    };
    return <div style={{ ...style }}>
        <input
            //label="Search"
            // variant="outlined"
            placeholder={placeholder}
            value={searchText}
            onChange={handleInputChange}
            style={{ border: '1px solid #1976d2', height: 30, padding: 0, ...inputStyle }}
        />
        <DButton text={btn_text || 'Search'} onClick={handleSearch} style={{ borderRadius: 0, marginLeft: -1 }} />
    </div>
}