// use https://metatags.io/ to test your website

import { Helmet } from "react-helmet";
import { TSeoConfig } from "./DAppCommand";

export const DHelmet = ({ config }: { config: TSeoConfig }) => {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{config.page_title || "Welcome to Home page"}</title>
            <meta name="description" content={config.page_description || " Welcome to this page"} />
            <meta name="keywords" content={config.keywords || ""} />
            <meta name="robots" content="index, follow" />
            <meta name="author" content="D Software pvt ltd"></meta>
            <meta property="og:title" content={config.page_title}></meta>
            <meta property="og:image" content={config.img || "https://www.example.com/images/uk-test-online-course.jpg"}></meta>
        </Helmet>
    );
};