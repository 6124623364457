import { Alert, CircularProgress } from "@mui/material";
import React, { CSSProperties, useState } from "react";
import { TNotification } from "./DAppCommand";
import { DRow } from "./DRow";
import { DText } from './DText';
export const DAlert = ({ notification, style }: { notification: TNotification, style?: CSSProperties }) => {
    const [open, setOpen] = useState(true);
    React.useEffect(() => {
        setOpen(true)
    }, [notification])
    if (!open) {
        return <></>
    }
    return <Alert onClose={() => setOpen(false)} severity={notification.type} sx={{ width: 'auto', height: 50 }} style={style}>
        <DRow style={{ alignItems: 'center' }}> {notification.is_loading ? <CircularProgress size={15} style={{ marginRight: 10 }} /> : null}<DText style={{ flex: 1 }}>{notification.msg}</DText></DRow>
    </Alert>
}