import EditIcon from '@mui/icons-material/Edit';
import HomeIcon from '@mui/icons-material/Home';
import { LoadingButton } from "@mui/lab";
import { Alert, Avatar, Button, IconButton, TextField, Typography } from "@mui/material";
import { dcolors, dnetwork, TObject } from "corexxx";
import React, { CSSProperties, useState } from "react";
import { DTextWithControl } from '../../algoverse/pages/Common';
import { DWebTS } from "../common_ts/DWebTS";
import { DAlternativeScreen, DAlternativeScreenHolder } from './DAlternativeScreen';
import { useDAppCommand } from "./DAppCommand";
import { DAvatarWithChangePicture } from "./DAvatarWithChangePicture";
import { DButton } from './DButton';
import { DCol } from "./DCol";
import { DFormDialog } from "./DForm";
import { DGoogleLoginButton } from "./DGoogleLoginButton";
import { DListOfTitleSubtitleButton } from './DListOfTitleSubTitleButton';
import { TNavigationItem } from "./DPageRootLayout";
import { DRow } from "./DRow";
import { DSeparator } from "./DSeparator";
import { DSpace } from "./DSpace";
import { DText } from "./DText";
export const AccountMenu = ({ style }: { style?: CSSProperties }) => {
    let appCommand = useDAppCommand();
    return appCommand.accountState ? (
        <DRow style={{ flex: 0 }}>
            <Button>
                <Avatar
                    sx={{ width: 32, height: 32 }}
                    alt={appCommand.accountState.name}
                    src={appCommand.accountState.image}
                    onClick={() => {
                        appCommand.api.navigate("/account");
                    }}
                />
            </Button>
        </DRow>
    ) : (
        <Button
            style={{ color: "white" }}
            onClick={() => {
                appCommand.api.navigate("/account");
            }}
        >
            Join now
        </Button>
    );
};

export const AccountScreen = ({
    title,
    config,
}: {
    title?: string;
    config?: { style?: CSSProperties; extra_ele?: JSX.Element; extra_link?: TNavigationItem[] };
}) => {
    let appCommand = useDAppCommand();
    const [activeScreen, setActiveScreen] = useState<"signin" | "signup" | "forgetpassword" | "user_info" | "login_sessions">(
        appCommand.accountState?.auth_token_ ? "user_info" : "signin"
    )

    let [username, set_username] = React.useState("");
    let [allSessions, setAllSession] = React.useState<TObject[]>([])
    let [token, set_token] = React.useState("");
    let [email, set_email] = React.useState("");
    let [password, set_password] = React.useState("");
    let [error, set_error] = React.useState("");
    let [loading, set_loading] = React.useState(false);
    let [step, set_step] = React.useState(1);
    function clear() {
        set_email("");
        set_password("");
        set_username("");
        set_error("");
        set_token("");
    }
    React.useEffect(() => {
        clear();
    }, []);

    React.useEffect(() => {
        if (appCommand.accountState) {
            setActiveScreen("user_info");
        } else {
            setActiveScreen("signin");
        }
    }, [appCommand.accountState]);

    return (
        <DCol style={{ maxWidth: 420, minHeight: 480, ...config?.style }}>
            {title ? (
                <>
                    <Typography
                        style={{ color: dcolors.pallets.blue600, fontSize: 15, textAlign: "center", textTransform: "uppercase", paddingBottom: 10 }}
                    >
                        {title}
                    </Typography>
                    <DSeparator style={{ marginBottom: 20 }} />
                </>
            ) : null}

            <DCol style={{ padding: 0, width: "100%", boxSizing: "border-box" }}>
                {error.length > 0 ? (
                    <Alert severity="error" style={{ marginBottom: 10 }}>
                        {error}
                    </Alert>
                ) : null}
                <DAlternativeScreenHolder activescreen={activeScreen}>
                    <DAlternativeScreen name="forgetpassword">
                        <DCol style={{ display: step == 1 ? "flex" : "none" }}>
                            <DText style={{ marginBottom: 20 }}>Step 1: Enter your username and get Token via Mail</DText>
                            <TextField
                                id="outlined-basic"
                                label="Enter Username"
                                variant="outlined"
                                style={{}}
                                value={username}
                                onChange={(e) => set_username(e.target.value)}
                            />
                            <LoadingButton
                                loading={loading}
                                variant="contained"
                                style={{ width: "100%", marginTop: 24 }}
                                onClick={async () => {
                                    set_loading(true);
                                    set_error("");
                                    try {
                                        let res = await dnetwork.postSimpleStore(appCommand.state.simpleStoreEndpoint + "/api/simple_auth/forgot_password", {
                                            username: username,
                                        });
                                        set_error(res.msg);
                                        set_step(2);
                                    } catch (e: any) {
                                        set_error(e.message);
                                    }
                                    set_loading(false);
                                }}
                            >
                                Send Token via mail
                            </LoadingButton>
                        </DCol>
                        <DCol style={{ display: step == 2 ? "flex" : "none" }}>
                            <DText style={{ marginBottom: 0, marginTop: 20 }}>Step 2: Enter the token and new password</DText>
                            <TextField
                                id="outlined-basic"
                                label="Token"
                                variant="outlined"
                                style={{ marginTop: 24 }}
                                type="text"
                                value={token}
                                onChange={(e) => set_token(e.target.value)}
                            ></TextField>
                            <TextField
                                id="outlined-basic"
                                label="Password"
                                variant="outlined"
                                style={{ marginTop: 24 }}
                                type="password"
                                value={password}
                                onChange={(e) => set_password(e.target.value)}
                            ></TextField>
                            <LoadingButton
                                loading={loading}
                                variant="contained"
                                style={{ width: "100%", marginTop: 24 }}
                                onClick={async () => {
                                    set_loading(true);
                                    set_error("");
                                    try {
                                        let res = await dnetwork.postSimpleStore(appCommand.state.simpleStoreEndpoint + "/api/simple_auth/reset_password", {
                                            username: username,
                                            short_lived_token: token,
                                            password_: password,
                                        });
                                        clear();
                                        set_error(res.msg);
                                        setActiveScreen("signin");
                                        set_step(1);
                                    } catch (e: any) {
                                        set_error(e.message);
                                    }
                                    set_loading(false);
                                }}
                            >
                                Reset password
                            </LoadingButton>
                        </DCol>
                    </DAlternativeScreen>

                    <DAlternativeScreen name="signin">
                        <DText style={{ marginBottom: 20, fontSize: 20 }}>Sign In</DText>
                        <DText style={{ marginBottom: 20, fontSize: 15, color: dcolors.pallets.grey500 }}>Note: You can utilize the same username and password to log in to multiple applications within the Grodok family.</DText>
                        <TextField
                            id="outlined-basic"
                            label="Username"
                            variant="outlined"
                            style={{}}
                            value={username}
                            onChange={(e) => set_username(e.target.value)}
                        />
                        <TextField
                            id="outlined-basic"
                            label="Password"
                            variant="outlined"
                            style={{ marginTop: 24 }}
                            type="password"
                            value={password}
                            onChange={(e) => set_password(e.target.value)}
                        ></TextField>
                        <LoadingButton
                            loading={loading}
                            variant="contained"
                            style={{ width: "100%", marginTop: 24 }}
                            onClick={async () => {
                                set_loading(true);
                                set_error("");
                                try {
                                    let res = await dnetwork.postSimpleStore(appCommand.state.simpleStoreEndpoint + "/api/simple_auth/login", {
                                        username: username,
                                        password_: password,
                                        app_name: appCommand.state.appConfig?.app_name
                                    });
                                    appCommand.setUserLogin(DWebTS.decode(res.out));
                                    clear();
                                } catch (e) {
                                    set_error("Not able to login");
                                }
                                set_loading(false);
                            }}
                        >
                            Sign In
                        </LoadingButton>
                        <Button
                            style={{ marginTop: 20, textTransform: "none" }}
                            onClick={() => {
                                setActiveScreen("signup");
                                clear();
                                DWebTS.danalytics.report_action("signup_btn_clicked");
                            }}
                        >
                            No account yet? Register now
                        </Button>
                        <Button
                            style={{ marginTop: 0, textTransform: "none" }}
                            onClick={() => {
                                setActiveScreen("forgetpassword");
                                clear();
                                DWebTS.danalytics.report_action("forgetpassword_btn_clicked");
                            }}
                        >
                            Forget password? Reset now.
                        </Button>
                        {appCommand.getAppConfig()?.google_client_id ? (
                            <>
                                <DRow style={{ marginTop: 10, marginBottom: 10 }}>
                                    <DSpace style={{ border: "1px solid #f1f1f1", marginRight: 10 }} />
                                    <DText>OR</DText>
                                    <DSpace style={{ border: "1px solid #f1f1f1", marginRight: 10 }} />
                                </DRow>
                                <DGoogleLoginButton google_client_id={appCommand.getAppConfig()?.google_client_id!!} />
                            </>
                        ) : null}
                    </DAlternativeScreen>

                    <DAlternativeScreen name="signup">
                        <>
                            <DText style={{ marginBottom: 20, fontSize: 20 }}>Create new account</DText>
                            <DText style={{ marginBottom: 20, fontSize: 15, color: dcolors.pallets.grey500 }}>Note: You can utilize the same username and password to log in to multiple applications within the Grodok family.</DText>
                            <TextField
                                id="outlined-basic"
                                label="Email"
                                variant="outlined"
                                style={{ marginBottom: 24 }}
                                value={email}
                                onChange={(e) => set_email(e.target.value)}
                            />
                            <TextField
                                id="outlined-basic"
                                label="Username"
                                variant="outlined"
                                style={{}}
                                value={username}
                                onChange={(e) => set_username(e.target.value)}
                            />
                            <TextField
                                id="outlined-basic"
                                label="Password"
                                variant="outlined"
                                style={{ marginTop: 24 }}
                                type="password"
                                value={password}
                                onChange={(e) => set_password(e.target.value)}
                            ></TextField>
                            <LoadingButton
                                loading={loading}
                                variant="contained"
                                style={{ width: "100%", marginTop: 24 }}
                                onClick={async () => {
                                    set_loading(true);
                                    set_error("");
                                    try {
                                        await dnetwork.postSimpleStore(appCommand.state.simpleStoreEndpoint + "/api/simple_auth/register", {
                                            email: email,
                                            username: username,
                                            password_: password,
                                        });
                                        set_error("registered Successfully. Now please login to continue");
                                        setActiveScreen("signin");
                                        clear();
                                    } catch (e: any) {
                                        set_error(`Not able to register. ${e.message}`);
                                    }
                                    set_loading(false);
                                    DWebTS.danalytics.report_action("register_btn_clicked");
                                }}
                            >
                                Register
                            </LoadingButton>
                            <Button
                                style={{ marginTop: 20, textTransform: "none" }}
                                onClick={() => {
                                    setActiveScreen("signin");
                                    clear();
                                    DWebTS.danalytics.report_action("signin_btn_clicked");
                                }}
                            >
                                Already having a account? login.
                            </Button>
                        </>
                    </DAlternativeScreen>

                    <DAlternativeScreen name="user_info">
                        <DCol style={{ alignItems: "center", flex: 0 }}>
                            <DRow style={{ width: "100%", marginBottom: 30, position: "relative" }}>
                                <IconButton href="/" style={{ marginLeft: -10, marginTop: -10 }}>
                                    <HomeIcon />
                                </IconButton>
                                <DSpace />
                                <DFormDialog
                                    prefill={appCommand.accountState}
                                    model={{
                                        data: [
                                            { type: "input", name: "name" },
                                            { type: "number", name: "phone" },
                                            { type: "input", name: "username", disabled: true },
                                            { type: "input", name: "email", disabled: true },
                                        ],
                                    }}
                                    dialogConfig={{ title: "Upload your account", triggerIcon: <EditIcon />, triggerStyle: { marginRight: -10, marginTop: -10 } }}
                                    onSubmit={(obj) => {
                                        appCommand.updateAccount(obj);
                                    }}
                                />
                            </DRow>
                            <DAvatarWithChangePicture
                                alt={appCommand.accountState?.name}
                                src={appCommand.accountState?.image}
                                sx={{ width: 80, height: 80 }}
                                onChange={async (file) => {
                                    const path = await DWebTS.uploadAFile(file, { file_name: appCommand.accountState?._id + "profile_pic.png", user_id: appCommand.accountState?.username || '' });
                                    await appCommand.updateAccount({ image: path });
                                }}
                            />
                            <DText style={{ fontWeight: "bold", marginTop: 20 }}>{appCommand?.accountState?.name || "Unknown"}({appCommand?.accountState?.username || ''})</DText>
                            {appCommand?.accountState?.email ? <DText style={{ marginBottom: 0 }}>{appCommand?.accountState?.email}</DText> : null}
                            {appCommand?.accountState?.phone ? <DText style={{ marginBottom: 20 }}>Phone:{appCommand?.accountState?.phone}</DText> : null}
                        </DCol>
                        <DSpace />
                        {config?.extra_ele}
                        <DCol style={{ flex: 0 }}>
                            {config?.extra_link?.map((y, idy) => (
                                <Button key={idy} variant="outlined" href={y.href} style={{ marginBottom: 10 }}>
                                    {y.text}
                                </Button>
                            ))}

                            <Button
                                variant="contained"
                                style={{ background: dcolors.pallets.red500, marginBottom: 10 }}
                                onClick={() => {
                                    appCommand.logout();
                                    clear();
                                    DWebTS.danalytics.report_action("logout_btn_clicked");
                                }}
                            >
                                Logout
                            </Button>
                            <Button
                                style={{ textTransform: 'lowercase', fontSize: 12 }}
                                onClick={async () => {
                                    appCommand.api.safeCall(async () => {
                                        setAllSession(DWebTS.decode((await appCommand.api.getSimpleStore(appCommand.state.simpleStoreEndpoint + '/api/simple_auth/get_all_active_session')).out))
                                        setActiveScreen("login_sessions")
                                    });
                                }}
                            >
                                Review all active session?
                            </Button>
                        </DCol>
                    </DAlternativeScreen>
                    <DAlternativeScreen name="login_sessions">
                        <DTextWithControl
                            backBtnCallback={() => {
                                setActiveScreen("user_info")
                            }}
                            text='All Active Sesssion'
                        />
                        <DListOfTitleSubtitleButton
                            data={allSessions.map(x => (
                                {
                                    title: `${x.auth_token_ == appCommand.state.auth_token_ ? '**[current session]' : ''} ${x.app_name}, IP: ${x.endpoint?.remote_ip}, ${x.endpoint?.host}`,
                                    subtitle: `${x.endpoint?.user_agent}`,
                                    extra: x

                                }))}
                            btn={{
                                text: 'Logout', onClick: async (x) => {
                                    appCommand.api.safeCall(async () => {
                                        await dnetwork.getSimpleStore(appCommand.state.simpleStoreEndpoint + `/api/simple_auth/logout?auth_token_=${x.auth_token_}&username=${appCommand.accountState?.username}`)
                                        setAllSession(DWebTS.decode((await appCommand.api.getSimpleStore(appCommand.state.simpleStoreEndpoint + '/api/simple_auth/get_all_active_session')).out))
                                    }, "Logout done", "Failed to logout");
                                }
                            }} />
                        <DButton text="Logout All" onClick={async () => {
                            appCommand.api.safeCall(async () => {
                                await dnetwork.getSimpleStore(appCommand.state.simpleStoreEndpoint + `/api/simple_auth/logout_all?auth_token_=${appCommand.accountState?.auth_token_}&username=${appCommand.accountState?.username}`)
                                await appCommand.logout()
                            }, "Logout all done", "Failed to logout all");
                        }} style={{ marginTop: 10 }} />
                    </DAlternativeScreen>
                </DAlternativeScreenHolder>
                <DText style={{ textAlign: 'center', marginTop: 30, fontSize: 12 }}>Powered by grodok.com. T&C Apply</DText>
            </DCol>
        </DCol>
    );
};
