import { Button, Menu, MenuItem } from "@mui/material";
import { TVoidCalBack } from "corexxx";
import React, { CSSProperties } from "react";

export function DPopUpMenu({ btnText, actions, btnStyle }: { btnText: string, btnStyle?: CSSProperties, actions: { text: string, onClick?: TVoidCalBack, href?: string }[] }) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                style={btnStyle}
            >
                {btnText}
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >

                {actions.map((x, idx) => {
                    let onclick = undefined
                    if (x.onClick) {
                        onclick = () => {
                            handleClose()
                            x.onClick?.()
                        }
                    }
                    return (<MenuItem key={idx} >
                        <Button href={x.href} onClick={onclick}>{x.text}</Button>
                    </MenuItem>)
                })
                }
            </Menu>
        </div>
    );
}