import { Box } from "@mui/material";
import { CSSProperties } from "react";
import { DBox } from "./DBox";
import { DCol } from "./DCol";
export const DResponsiveWindow = ({ children, width, style, boxed, className }: { children?: any; width?: number; style?: CSSProperties; boxed?: boolean, className?: string }) => {
    return (
        <Box
            className={className}
            sx={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
                flex: 1,
                boxSizing: "border-box",
                alignItems: "center",
                maxWidth: "100%",
                width: {
                    xs: "calc(100% - 8px)", // theme.breakpoints.up('xs')
                    sm: "calc(100% - 40px)", // theme.breakpoints.up('sm')
                    md: width || 900, // theme.breakpoints.up('md') //>900
                    lg: width || 900, // theme.breakpoints.up('lg') // 900
                    xl: width || 1200, // theme.breakpoints.up('xl') // 1536
                },
                margin: {
                    xs: "0 4px", // theme.breakpoints.up('xs')
                    sm: "0 20px", // theme.breakpoints.up('sm')
                    md: "0 auto", // theme.breakpoints.up('md')
                    lg: "0 auto", // theme.breakpoints.up('lg')
                    xl: "0 auto", // theme.breakpoints.up('xl')
                },
            }}
        >
            {boxed ? (
                <DBox style={{ margin: 20, width: "100%", justifyItems: "center", alignItems: "center", padding: 20, ...style }}>{children}</DBox>
            ) : (
                <DCol style={{ width: "100%", ...style }}>{children || null}</DCol>
            )}
        </Box>
    );
};