import { TObject, dlog } from "corexxx";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { DHook } from "../common_hooks/DHook";
import { DWebTS } from "../common_ts/DWebTS";
import { TAppConfig, useDAppCommand } from "./DAppCommand";
export const DAppInit = React.memo(({ appConfig }: { appConfig: TAppConfig }) => {
    dlog.d("DAppInit called ");
    const appCommand = useDAppCommand();
    const [searchParams, setSearchParams] = useSearchParams();
    const path = DHook.useReactPath();
    //init
    React.useEffect(() => {
        appCommand.init(appConfig);
        if (appConfig.google_analytics_tag) {
            DWebTS.danalytics.init(appConfig.google_analytics_tag);
        }
    }, []);

    // this hook will log the path
    React.useEffect(() => {
        DWebTS.danalytics.pageview(window.location.pathname + window.location.search);
    }, [path]);

    React.useEffect(() => {
        /* tslint:disable */
        let list: TObject = {};
        for (let i of [...(searchParams as any).keys()]) {
            if (i.startsWith("gk_")) {
                list[i] = searchParams.get(i) === "1" || searchParams.get(i) === "true";
            }
        }
        appCommand.setGkState(list);
    }, [searchParams]);
    return <></>;
});