export namespace corexxx {
    export function generateRandomDarkColor(): string {
        // Function to generate a random hexadecimal value between 0 and 255
        function getRandomHex(): string {
            return Math.floor(Math.random() * 256).toString(16).padStart(2, '0');
        }

        // Generate random values for each color component
        const red = getRandomHex();
        const green = getRandomHex();
        const blue = getRandomHex();

        // Concatenate the color components and return the color code
        return `#${red}${green}${blue}`;
    }

    export function generateRandomLightColor(): string {
        // Function to generate a random hexadecimal value between 180 and 255
        function getRandomLightHex(): string {
            return Math.floor(Math.random() * 76 + 180).toString(16).padStart(2, '0');
        }

        // Generate random values for each color component
        const red = getRandomLightHex();
        const green = getRandomLightHex();
        const blue = getRandomLightHex();

        // Concatenate the color components and return the color code
        return `#${red}${green}${blue}`;
    }
}