import { Button, ButtonGroup, Menu, MenuItem } from "@mui/material";
import { TVoidCalBack } from "corexxx";
import React from "react";

export const DSplitButton = ({ actions }: { actions: { text: string, onClick: TVoidCalBack }[] }) => {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        event.preventDefault();
        event.stopPropagation();
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <ButtonGroup variant="contained" style={{}}>
            <Button onClick={actions[0].onClick}>
                {actions[0].text}
            </Button>
            <Button
                color="primary"
                size="small"
                aria-label="select option"
                aria-controls={open ? "split-button-menu" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                ▼
            </Button>
            <Menu
                id="long-menu"
                MenuListProps={{
                    "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {actions.slice(1).map((option) => (
                    <MenuItem
                        key={option.text}
                        onClick={(e) => {
                            option.onClick();
                            handleClose();
                            e.stopPropagation();
                        }}
                    >
                        {option.text}
                    </MenuItem>
                ))}
            </Menu>
        </ButtonGroup>
    );
}