import { dcolors } from "corexxx";
import { CSSProperties } from "react";
import { DCenter } from "./DCenter";
import { DText } from "./DText";

export const DTextEmptyView = ({ style, icon, title, subtitle, extra_comp }: { icon?: any; title?: string; subtitle?: string; extra_comp?: any, style?: CSSProperties }) => {
    return (
        <DCenter style={style}>
            {icon}
            <DText mode="title" style={{ marginBottom: 20, width: 440, color: dcolors.pallets.black, fontSize: 32, lineHeight: 1.2 }}>
                {title}
            </DText>
            <DText mode="subtitle" style={{ marginBottom: 20, width: 450, lineHeight: 1 }}>
                {subtitle}
            </DText>
            {extra_comp}
        </DCenter>
    );
};