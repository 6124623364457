import { dcolors } from "corexxx";
import { CSSProperties } from "react";
import { corexxx } from "../../copy";
import { DCol } from "./DCol";
import { DRow } from "./DRow";
import { DText } from "./DText";

export function DashboardTiles({ data }: { data: { link?: string, title: string, value: string, subtext?: string, color?: string, errorColor?: boolean, valueStyle?: CSSProperties }[] }) {
    return (<DRow>
        {data.map((x, idx) => {
            let backColor = x.color || corexxx.generateRandomDarkColor();
            if (x.errorColor != undefined) {
                backColor = x.errorColor ? dcolors.pallets.red500 : dcolors.pallets.green500;
            }
            return (<div key={idx} style={{ flex: '0 0 auto', width: 250, height: 150, padding: 30, margin: 10, color: 'white', background: backColor, alignItems: 'center' }}>
                <DText style={{ textAlign: 'center', color: dcolors.pallets.grey300, marginBottom: 5, fontSize: 16 }}>{x.title}</DText>
                <DText style={{ textAlign: 'center', color: 'white', marginBottom: 5, fontSize: 36, fontWeight: 'bold', ...x.valueStyle }}>{x.value}</DText>
                {x.subtext ? <DText style={{ textAlign: 'center', color: dcolors.pallets.grey300, marginBottom: 5, fontSize: 12 }}>{x.subtext}</DText> : null}
            </div>)
        })
        }
    </DRow>)
}

export const DashboardNumber = ({ number, text, style }: { number: string, text: string, style?: CSSProperties }) => {
    return <DCol style={{ padding: 10, ...style }}>
        <DText style={{ textAlign: 'center', fontSize: 40, fontWeight: 'bolder', color: 'white', lineHeight: 1 }}>{number}</DText>
        <DText style={{ textAlign: 'center', fontSize: 13, textTransform: 'uppercase', color: 'white', opacity: 0.6, lineHeight: 1, marginTop: 10 }}>{text}</DText>
    </DCol>
}
