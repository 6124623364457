import { CSSProperties } from "react";

export const DCenter = ({ children, style }: { children?: any; style?: CSSProperties }) => {
    return (
        <div
            style={{
                display: "flex",
                flex: 1,
                alignItems: "center",
                flexDirection: "column",
                textAlign: "center",
                justifyContent: "center",
                ...style,
            }}
        >
            {children || null}
        </div>
    );
};
