// @ts-nocheck

import Editor from "@monaco-editor/react";
import { PlayCircleFilled } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { LoadingButton } from "@mui/lab";
import { Button, ButtonGroup, Menu, MenuItem } from "@mui/material";
import { TObject, TStringCallback, dcolors, dlog, dnetwork } from "corexxx";
import React, { CSSProperties, useEffect, useState } from "react";
import CountUp from "react-countup";
import ReactQuill from "react-quill";
import '../css/quill.snow.css';
import { useDAppCommand } from "./DAppCommand";
import { DCol } from "./DCol";
import { DHtml } from "./DHtml";
import { DRow } from "./DRow";
import { DSpace } from "./DSpace";
import { DText } from "./DText";
export type TSavedCode = {
    _id?: string;
    title?: string;
    code: string;
};
/******************************************************
 * Editors
 *
 *********************************************************/
export function CodeCompiler({
    style,
    onSave,
    savedCode,
    height,
    extraButton,
}: {
    style?: CSSProperties;
    onSave?: TStringCallback;
    savedCode?: TSavedCode;
    height?: string;
    extraButton?: JSX.Element;
}) {
    let [code, setCode] = React.useState<string>(savedCode?.code || "");

    let [debug, setDebug] = React.useState<boolean>(false);
    let [output, setOutput] = React.useState<string>("");
    let appCommand = useDAppCommand();
    let [loading, setLoading] = React.useState(false);
    const editor = React.useRef<monaco.editor.IStandaloneCodeEditor | null>(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    /* -- WE DONT need this
    useScript({
      src: "https://cdnjs.cloudflare.com/ajax/libs/js-beautify/1.14.7/beautify.js",
      onload: () => {
        console.log("script js-beautify loaded!");
        // @ts-ignore:
        js_beautify(code, { indent_size: 2, space_in_empty_paren: true });
      },
    });
    */

    const handleClose = () => {
        setAnchorEl(null);
    };
    React.useEffect(() => {
        editor.current?.setValue(savedCode?.code || "");
    }, [savedCode]);

    async function execute() {
        setLoading(true);
        try {
            let res = (await dnetwork.post(
                debug ? "http://localhost/api/utils/rce/execute" : "https://simplestore.dipankar.co.in/api/utils/rce/execute",
                {
                    _dev_token: "root",
                    lang: "cpp",
                    code: code,
                }
            )) as TObject;
            appCommand.setNotification({ type: res.status, msg: res.msg });
            console.log(res);

            setOutput(`${res.out.html || res.out}<p>(Note: Time taken to execute: ${res.out.time_taken} ms)`);
        } catch (e: any) {
            console.log(e);
            appCommand.setNotification({ type: "error", msg: e.message });
        }
        setLoading(false);
    }

    return (
        <DCol style={{ position: "relative", ...style, display: "flex" }}>
            <DRow style={{}}>
                <CountUp
                    start={0}
                    end={3600}
                    duration={60 * 60}
                    formattingFn={(timer) => {
                        let seconds = timer % 60;
                        let minutes = Math.floor(timer / 60) % 60;
                        let hours = Math.floor(timer / 60 / 60);
                        return (
                            (hours > 9 ? hours : "0" + hours) + ":" + (minutes > 9 ? minutes : "0" + minutes) + ":" + (seconds > 9 ? seconds : "0" + seconds)
                        );
                    }}
                >
                    {({ countUpRef, start }) => (
                        <ButtonGroup style={{ marginRight: 10 }}>
                            <Button>
                                <span ref={countUpRef} />
                            </Button>
                            <Button onClick={start}>Reset</Button>
                        </ButtonGroup>
                    )}
                </CountUp>
                <DText>Code Execution Engine</DText>
                <DSpace />
                <ButtonGroup variant="outlined" aria-label="outlined primary button group">
                    <LoadingButton onClick={execute} loading={loading} variant="outlined">
                        <PlayCircleFilled style={{ marginRight: 10 }} />
                        Run
                    </LoadingButton>
                    <Button
                        onClick={() => {
                            setCode('#include<iostream>\nint main(){\n    std::cout<<"Hello World"<<std::endl;\n    return 0;\n}');
                        }}
                    >
                        Preset
                    </Button>

                    {onSave ? (
                        <Button
                            onClick={() => {
                                onSave?.(code);
                            }}
                        >
                            Save{`${savedCode?._id?.length || 0 > 0 ? "*" : ""}`}
                        </Button>
                    ) : null}
                    <>{extraButton}</>
                </ButtonGroup>
                <Button
                    id="demo-customized-button"
                    aria-controls={open ? "demo-customized-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    variant="contained"
                    style={{ marginLeft: 20 }}
                    disableElevation
                    onClick={handleClick}
                    endIcon={<KeyboardArrowDownIcon />}
                >
                    Options
                </Button>
                <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                    <MenuItem
                        onClick={() => {
                            setDebug(!debug);
                            appCommand.setNotification({ type: "success", msg: "Debug enabled:" + !debug });
                            handleClose();
                        }}
                    >
                        Use Debug Endpoint
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            // @ts-ignore: Next line is ignored
                            setCode(beautify_js.js(code, { indent_size: 2, space_in_empty_paren: true }));
                            handleClose();
                        }}
                    >
                        Format
                    </MenuItem>
                </Menu>
            </DRow>
            <DRow style={{ alignItems: "start", borderTop: "1px solid #f1f1f1", paddingTop: 0, marginTop: 10 }}>
                <Editor
                    onMount={(e) => {
                        editor.current = e;
                    }}
                    width={"calc(100% - 420px)"}
                    height={height || "calc(100vh - 180px)"}
                    defaultLanguage="objective-c"
                    options={{
                        renderLineHighlight: "none",
                        formatOnPaste: true,
                        formatOnType: true,
                        autoIndent: "brackets",
                        minimap: {
                            enabled: false,
                        },
                    }}
                    defaultValue={code}
                    value={code}
                    onChange={(data) => {
                        if (data) {
                            setCode(data);
                        }
                    }}
                ></Editor>
                <div style={{ flex: "auto", width: 420, paddingLeft: 30, background: "white", marginLeft: 5, height: height || "calc(100vh - 180px)" }}>
                    {output ? <DHtml html={`<pre>${output}</pre>`} /> : null}
                    {!output ? (
                        <DText
                            style={{
                                color: dcolors.pallets.grey400,
                                justifyContent: "center",
                                alignSelf: "center",
                                fontSize: 20,
                                textAlign: "center",
                                marginTop: 20,
                            }}
                        >
                            Output would be shown here
                        </DText>
                    ) : null}
                </div>
            </DRow>
        </DCol>
    );
}

// KNOWN BUG AUTO SAVE WNOT WORK AS EXPECTED
export function RichTextEditor({ onChange, style, onSave, initValue, rightExtraView, onAutoSave }: {
    onSave?: TStringCallback,
    onAutoSave?: TStringCallback, onChange?: TStringCallback, style?: React.CSSProperties, initValue?: string, rightExtraView?: any
}) {
    const [value, setValue] = useState(initValue || '');
    const isDirtyChange = React.useRef<boolean>(false);

    React.useEffect(() => {
        setValue(initValue || '')
    }, [initValue])


    const handleChange = (content: any, _: any, __: any, editor: any) => {
        setValue(editor.getHTML());
        onChange?.(editor.getHTML())
        isDirtyChange.current = true

    };

    // Implemnet autosave
    // If you chnage something and dont do anything fror 30 sec it will save - this is not the right behavious.
    useEffect(() => {
        const callback = () => {
            if (isDirtyChange.current) {
                dlog.d('autosave trigger')
                // This function must be changed as the value is cached
                onAutoSave?.(value)
                isDirtyChange.current = false

            } else {
                dlog.d('autosave ignored')
            }
        };
        // Set up the timer to call the callback every 30 second
        const intervalId = setInterval(callback, 30 * 1000);
        return () => {
            clearInterval(intervalId);
        };
    }, [value]);

    const formats = [
        'header',
        'font',
        'size',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
    ];

    const modules = {
        toolbar: [
            // [{ header: [1, 2, 3, 4, 5, 6, false] }],
            // [{ font: [] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ['blockquote', 'code-block'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            //['link', 'image', 'video'],
            //['clean'],
        ],
    };

    return (
        <DCol style={{ flex: 0, border: '1px solid black', ...style }}>
            <ReactQuill
                modules={modules}
                formats={formats}
                value={value} onChange={handleChange} style={{ border: 'none !important', flex: 1, overflow: 'auto', minHeight: 300, fontFamily: 'monospace', fontSize: 12 }} />
            {onSave ? <DRow style={{


                flex: 0, padding: 10, alignItems: 'center'
            }}>
                <DText>{onAutoSave ? 'Auto Save enabled * ' : ''}{value.length} chars</DText>
                <DSpace />
                <LoadingButton size="small" onClick={async () => {
                    await onSave?.(value);

                    isDirtyChange.current = false
                }
                } variant='contained'>{isDirtyChange.current ? '*' : ''}Save</LoadingButton>
                {rightExtraView}
            </DRow> : null}
        </DCol>

    );
}