import React, { useEffect, useRef, useState } from 'react';
import { DResponsiveWindow } from '../../dweb/DResponsiveWindow';
import { DBox } from '../../dweb/DBox';
import { DText } from '../../dweb/DText';
import { DRow } from '../../dweb/DRow';
import { DCol } from '../../dweb/DCol';
import { DButton } from '../../dweb/DButton';

export const DefaultWebRtcCallScreen: React.FC = () => {
    const [roomName, setRoomName] = useState<string>(''); // Room name
    const [isInCall, setIsInCall] = useState<boolean>(false); // Whether user is in a call
    const signalingSocket = useRef<WebSocket | null>(null); // WebSocket for signaling
    const localStream = useRef<MediaStream | null>(null); // Local media stream
    const localVideoRef = useRef<HTMLVideoElement | null>(null); // Local video element ref
    const remoteVideoRef = useRef<HTMLVideoElement | null>(null); // Remote video element ref
    const peerConnection = useRef<RTCPeerConnection | null>(null); // Peer connection
    const remoteStream = useRef<MediaStream | null>(null); // Remote media stream

    const handleRoomJoin = () => {
        if (!roomName) {
            alert('Please enter a room name');
            return;
        }
        signalingSocket.current = new WebSocket('ws://localhost:8080');

        signalingSocket.current.onopen = () => {
            console.log('WebSocket connection established');
            signalingSocket.current?.send(JSON.stringify({ type: 'join', room: roomName }));
            initializeMediaAndPeerConnection();
        };

        signalingSocket.current.onmessage = (event) => {
            const message = JSON.parse(event.data);
            handleSignalingMessage(message);
        };

        setIsInCall(true);
    };

    const initializeMediaAndPeerConnection = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
            localStream.current = stream;

            // Set up local video
            if (localVideoRef.current) {
                localVideoRef.current.srcObject = stream;
            }

            // Initialize PeerConnection
            peerConnection.current = new RTCPeerConnection({
                iceServers: [{ urls: 'stun:stun.l.google.com:19302' }],
            });

            peerConnection.current.onicecandidate = (event) => {
                if (event.candidate) {
                    signalingSocket.current?.send(JSON.stringify({ type: 'candidate', candidate: event.candidate, room: roomName }));
                }
            };

            peerConnection.current.ontrack = (event) => {
                if (!remoteStream.current) {
                    remoteStream.current = new MediaStream();
                    if (remoteVideoRef.current) {
                        remoteVideoRef.current.srcObject = remoteStream.current;
                    }
                }
                event.streams[0].getTracks().forEach((track) => {
                    remoteStream.current?.addTrack(track);
                });
            };

            // Add local stream tracks to the peer connection
            stream.getTracks().forEach((track) => {
                peerConnection.current?.addTrack(track, stream);
            });

            // Create and send an SDP offer
            const offer = await peerConnection.current.createOffer();
            await peerConnection.current.setLocalDescription(offer);
            signalingSocket.current?.send(JSON.stringify({ type: 'offer', offer: offer, room: roomName }));

        } catch (error) {
            console.error('Error accessing media devices:', error);
        }
    };

    const handleSignalingMessage = async (message: any) => {
        switch (message.type) {
            case 'offer':
                await peerConnection.current?.setRemoteDescription(new RTCSessionDescription(message.offer));
                const answer = await peerConnection.current?.createAnswer();
                await peerConnection.current?.setLocalDescription(answer);
                signalingSocket.current?.send(JSON.stringify({ type: 'answer', answer: answer, room: roomName }));
                break;
            case 'answer':
                await peerConnection.current?.setRemoteDescription(new RTCSessionDescription(message.answer));
                break;
            case 'candidate':
                if (message.candidate) {
                    await peerConnection.current?.addIceCandidate(new RTCIceCandidate(message.candidate));
                }
                break;
            default:
                break;
        }
    };

    const handleRoomInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRoomName(e.target.value);
    };

    const leaveCall = () => {
        peerConnection.current?.close();
        setIsInCall(false);
        signalingSocket.current?.close();
        localStream.current = null;
        if (localVideoRef.current) localVideoRef.current.srcObject = null;
        if (remoteVideoRef.current) remoteVideoRef.current.srcObject = null;
    };

    useEffect(() => {
        return () => {
            // Clean up peer connection and streams on component unmount
            leaveCall()
        };
    }, []);

    return (
        <DResponsiveWindow>
                <DBox style={{padding:0,background:'black', marginTop:10}}>
                    <DText dark style={{padding:10, fontSize:20}}>Test WebRtc Call</DText>
                    <DRow style={{flex:1}}>
                        <video ref={localVideoRef} autoPlay playsInline muted style={{ flex:1, width:'49%' }}></video>
                        <video ref={remoteVideoRef} autoPlay playsInline style={{ flex:1,width:'49%'}}></video>
                    </DRow>
                    <DRow style={{flex:0, padding:20, background:'black', color:'white'}}>
                    {!isInCall ? (
                        <DRow center>
                            <DText dark>Enter Room Name: </DText>
                            <input id="roomInput" type="text" value={roomName} onChange={handleRoomInput} />
                            <DButton onClick={handleRoomJoin} style={{marginLeft:10}}>Join Room</DButton>
                        </DRow>):(
                        <DRow center>
                            <DText dark style={{flex:1}}> You are in room: {roomName} </DText>
                            <DButton onClick={leaveCall} style={{marginLeft:10}}>Leave Room</DButton>
                        </DRow>
                        )}
                    </DRow>
                </DBox>
            
        </DResponsiveWindow>
    );
};

