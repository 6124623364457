import { useDAppCommand } from "../../libs/dweb/DAppCommand"
import { DSimpleStoreModel } from "../../libs/dweb/DSimplestoreModel"
import { BasePageNavigation } from "../App"

export function CronJobs() {
    let appCommand = useDAppCommand()
    return (<BasePageNavigation>
        <DSimpleStoreModel
            endpoint={appCommand.state.simpleStoreEndpoint + "/api/cron_job"}
            model={{ data: [] }}
            config={{ title: 'Backend Cron Jobs' }}
            tableViewColumns={[
                {
                    field: 'cron_format',
                    headerName: 'cron format',
                },

                {
                    field: 'executed_count',
                    headerName: 'executed count'
                },

                {
                    field: 'success_count',
                    headerName: 'success count'
                },

                {
                    field: 'is_active',
                    headerName: 'is active'
                },
                {
                    field: 'job_type',
                    headerName: 'job type',

                },
                {
                    field: 'last_error',
                    headerName: 'last error',
                }
            ]}
        />
    </BasePageNavigation>)
}