// src/components/SlidingBanner.tsx
import React, { useEffect, useState } from 'react';
interface SlidingBannerProps {
    images: string[];
}

export const DSlidingBanner: React.FC<SlidingBannerProps> = ({ images }) => {

    React.useLayoutEffect(() => {
        //@ts-ignore
        import('./DSlidingBanner.css')
        //@ts-ignore

    }, [])
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 3000); // Change slide every 3 seconds
        return () => clearInterval(interval);
    }, [images.length]);

    const goToSlide = (index: number) => {
        setCurrentIndex(index);
    };

    return (
        <div className="sliding-banner">
            <div
                className="slides"
                style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
                {images.map((image, index) => (
                    <div className="banner-slide" key={index} style={{ borderRadius: 5 }} >
                        <img src={image} alt={`Slide ${index}`} style={{ borderRadius: 5 }} />
                    </div>
                ))}
            </div>
            <div className="dots">
                {images.map((_, index) => (
                    <span
                        key={index}
                        className={`dot ${index === currentIndex ? 'active' : ''}`}
                        onClick={() => goToSlide(index)}
                    ></span>
                ))}
            </div>
        </div>
    );
};
