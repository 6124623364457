import { CSSProperties } from "react";
import { DCol } from "./DCol";
export const DBox = ({ children, style }: { children?: any; style?: CSSProperties }) => {
    return (
        <DCol
            style={{
                borderRadius: 4,
                display: "flex",
                background: "white",
                padding: 20,
                boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
                position: "relative",
                ...style,
            }}
        >
            {children || null}
        </DCol>
    );
};