import { dlog, dnetwork, TObject } from "corexxx";
// HELLO
export namespace SSAlgoTradingDef {

    export type TSubscribeAction = { ticker: string }
    export type TOrderType = 'MARKET' | 'LIMIT' | 'STOP' | 'STOP_LIMIT'
    export type Taction = 'BUY' | 'SELL'
    export type TDuration = 'DAY' | 'GOOD_TILL_CANCEL' | 'FILL_OR_KILL'
    export type TOrderStatus = 'WORKING' | 'FILLED' | 'CANCELED' | 'REJECTED' | 'REPLACED' | 'PENDING_ACTIVATION'
    export type TSession = 'NORMAL' | 'AM' | 'PM' | 'SEAMLESS'


    let simpleStoreEndpoint = "https://simplestore.dipankar.co.in"
    //let simpleStoreEndpoint = "http://localhost"

    export function setEndpoint(url: string) {
        simpleStoreEndpoint = url;
    }

    const AccountConfigEndPoint = '/api/remote_nodel_secure_grodok_algoverse_account_config';

    // This quote is saved here to validate orders
    let quoteDataCache: TLiveQuoteMap = {}

    // define const
    export const OrderTypeList: TOrderType[] = ['MARKET', 'LIMIT', 'STOP', 'STOP_LIMIT']
    export const DurationList: TDuration[] = ['DAY', 'GOOD_TILL_CANCEL', 'FILL_OR_KILL']
    export const SessionList: TSession[] = ['NORMAL', 'AM', 'PM', 'SEAMLESS']
    export const ActionList: Taction[] = ['BUY', 'SELL']
    export const OrderStatusList: TOrderStatus[] = ['WORKING', 'FILLED', 'CANCELED', 'REJECTED', 'REPLACED', 'PENDING_ACTIVATION']

    export type TSimpleOrderPayload = {
        account_number: string,
        ticker: string,
        quantity: number,
        session: TSession,

        action: Taction,
        duration: TDuration,
        order_type: TOrderType,
        limit_price?: number,
        stop_price?: number,
        a_type: 'EQUITY' | 'OPTION'
    }


    export type TTrailingStopOrderPayload = {
        account_number: string,
        ticker: string,
        quantity: number,
        session: TSession,

        stop_price_offset: number
    }

    // sell on limit or sell on limit
    export type TOcoOrderPayload = {
        account_number: string,
        ticker: string,
        quantity: number,
        session: TSession,

        target_price: number,
        stop_price: number,
    }

    // Buy - target
    export type TOtoOrderPayload = {
        account_number: string,
        ticker: string,
        quantity: number,
        session: TSession,

        buy_price: number
        target_price: number,
    }

    // BUY - target - stop
    export type TOttOrderPayload = {
        account_number: string,
        ticker: string,
        quantity: number,
        session: TSession,

        buy_price: number,
        target_price: number,
        stop_price: number,
    }

    export type TPortfolioSummary = {
        initial_amount: number,
        current_amount: number
        invested_amount: number,
        cash_amount: number, /// cash in hand
        total_return: number,
        total_return_str: string,
        realized_pl: number;
        interested_symbol: { symbol: string, quantity: number }[]
    }

    // Parsing account information
    export type TBrokerType = 'PAPER' | 'SCHWAB' | "MANUAL"
    // TBrokerAccountInfo
    export type TBrokerAccountInformation = {
        account_type: TBrokerType,
        account_name: string,
        account_number: string,
        account_hash: string,
        initial_balance: TBalanceInformation,
        current_balance: TBalanceInformation,
        position_information?: TPositionInformation[], // depricated
        order_information: TOrderInformation[]
        portfolio_summary: TPortfolioSummary,
        algoVerseConfig: TAlgoVerseAccountConfig | null,
        position_summary: TPositionSummary[], // now we support multiple tickers,
        pivots?: { [key: string]: number[] }
    }

    export type TBalanceInformation = {
        cash_amount: number,
        margin_amount: number
        buying_power: number,
    }

    // TBrokerOrder
    export type TOrderInformation = {
        entry_id?: string,// if stored
        order_id: string,
        group_id: string,
        order_category: string,

        status: TOrderStatus,
        is_open: boolean,
        reason?: string,

        symbol: string
        asset_type: string
        quantity: number,
        limit_price: number, //price
        fill_price: number,
        stop_price: number,
        order_type: TOrderType,
        action: Taction,
        session: TSession,
        duration: TDuration
        order_strategy_type?: string,

        // ts
        entered_time: string, //opened_time
        closed_time?: string //closed_time
        tracker_time: string // this is the time where we should be tracking in perf
        ignore_order_for_tracking: boolean

        calculated_realized_pl?: number
    }

    export type TPositionSummary = {
        symbol: string,
        position_count: number
        position_avg: number
        realized_pl: number
        unrealized_pl: number
        buy_sell_summary: string,
    }


    // TPosition
    export type TPositionInformation = {
        quantity: number,
        symbol: string,
        average_price: number,
        current_day_pl: number,
        current_day_pl_percentage: number,
        ltp: number,
        invested: number
        market_value: number,
        long_profit_loss: number,
        long_profit_loss_percentage: number,

        // extra
        short_quantity: number,
        long_quantity: number,
        raw_data: TObject
    }

    export type TLiveQuote = {
        symbol: string,
        ask_price: number,
        bid_price: number,
        ask_size: number,
        bid_size: number,
        mark_price: number,
        open: number,
        high: number,
        close: number,
        low: number,
        quote_time: string,
        netChange: number,
        netPercentChange: number,
        postMarketChange?: number,
        postMarketPercentChange?: number
        preMarketChange?: number,
        preMarketPercentChange?: number
    }

    export type TLiveQuoteMap = {
        [key: string]: TLiveQuote
    }

    export type TAlgoVerseAccountConfig = {
        _id: string
        account_type: TBrokerType,
        account_name: string,
        account_number: string,
        account_capital: number,
        watch_list: string[], // watch for this account
        order_history: TOrderInformation[], // order history
        order_date_override: { [key: string]: string | null }
        ignore_order_list: string[]// string of string of order number
        pivots?: { [key: string]: number[] }
    }

    async function _getAlgoVerseConfig(auth_token_: string, account_number: string) {
        // defunat value
        let algoVerseConfig: TAlgoVerseAccountConfig = {
            _id: '',
            account_name: '',
            account_type: 'SCHWAB',
            account_capital: 0,
            account_number: '',
            watch_list: [], // watch for this account
            order_history: [],
            order_date_override: {},
            ignore_order_list: []
        }
        try {
            let resp = await dnetwork.getSimpleStore(`${simpleStoreEndpoint}${AccountConfigEndPoint}?auth_token_=${auth_token_}&account_number=${account_number}`)
            if (resp.out.length > 0) {
                algoVerseConfig = resp.out[0] as TAlgoVerseAccountConfig
            }
        } catch (e) {
        }
        // type conversion:
        Object.keys(algoVerseConfig.order_date_override || {}).forEach(key => {
            algoVerseConfig.order_date_override[key] = _buildDate(algoVerseConfig.order_date_override[key])
        })
        return algoVerseConfig;
    }

    export async function updateAlgoVerseAccountConfig(auth_token_: string, id: string, data: TObject) {
        await dnetwork.postSimpleStore(`${simpleStoreEndpoint}${AccountConfigEndPoint}/update`, { auth_token_: auth_token_, id: id, ...data })
    }


    function _getAction(order: TObject) {
        try {
            return order.orderLegCollection[0].instruction;
        } catch (e) {

        }
    }

    // this is a recusursive function
    function _parseOrder(order: TObject, order_information: TOrderInformation[], algoVerseConfig: TAlgoVerseAccountConfig) {
        // order to be considered

        if (order.orderLegCollection && order.orderLegCollection.length > 0) {
            let fill_price = 0
            try {
                fill_price = order.orderActivityCollection.filter((x: any) => x.executionType == 'FILL')?.[0].executionLegs[0].price;
            } catch (e) { }
            let action = _getAction(order)
            order_information.push({
                order_category: `${action}/${order.price ? 'LIMIT' : 'MARKET'}/${order.stopPrice ? 'STOP' : 'No-STOP'}/Session::${order.session}/Valid:${order.duration}`,
                order_id: order.orderId + '',
                group_id: order.accountNumber,
                session: order.session,
                duration: order.duration,
                order_type: order.orderType,
                quantity: order.quantity,
                limit_price: order.price,
                fill_price: fill_price,
                stop_price: order.stopPrice || 0,
                status: order.status,
                entered_time: order.enteredTime,
                closed_time: order.closeTime,
                tracker_time: algoVerseConfig.order_date_override[order.orderId + ''] || order.enteredTime,
                symbol: order.orderLegCollection[0].instrument.symbol,
                asset_type: order.orderLegCollection[0].instrument.assetType,
                action: action,
                is_open: order.status == 'WORKING' || order.status == 'PENDING_ACTIVATION' || order.status == 'AWAITING_PARENT_ORDER',
                ignore_order_for_tracking: algoVerseConfig.ignore_order_list.includes(order.orderId + '') || false,
            })
        }
        // consider child order too
        if (order.childOrderStrategies && order.childOrderStrategies.length > 0) {
            order.childOrderStrategies.forEach((order: any) => {
                _parseOrder(order, order_information, algoVerseConfig)
            })
        }
    }

    // It will fetch both data.
    let CACHE_SCHWAP_DATA: TObject = {}
    export const fetchInitData = async (auth_token_: string) => {
        let resp = await dnetwork.getSimpleStore(`${simpleStoreEndpoint}/api/utils/schwab/get_all_data?auth_token_=${auth_token_}`)
        CACHE_SCHWAP_DATA = resp.out
    }

    export const genAccountInformation = async (algoVerseConfig: SSAlgoTradingDef.TAlgoVerseAccountConfig, quote: SSAlgoTradingDef.TLiveQuoteMap): Promise<TBrokerAccountInformation> => {
        if (!CACHE_SCHWAP_DATA) {
            dlog.d("Schwa data is not yet there >>>> ERRIR <<")
            throw new Error("ERROR150-")
        }
        try {
            let account_hash = CACHE_SCHWAP_DATA.account_hash;
            let securitiesAccount = CACHE_SCHWAP_DATA.account_info.securitiesAccount
            let initial_balance: TBalanceInformation = {
                cash_amount: securitiesAccount.initialBalances.cashBalance,
                margin_amount: securitiesAccount.initialBalances.margin,
                buying_power: securitiesAccount.initialBalances.dayTradingBuyingPower,
            }
            let current_balance: TBalanceInformation = {
                cash_amount: securitiesAccount.currentBalances.cashBalance,
                margin_amount: securitiesAccount.currentBalances.margin,
                buying_power: securitiesAccount.currentBalances.dayTradingBuyingPower,
            }
            let order_information: TOrderInformation[] = []
            CACHE_SCHWAP_DATA.order_history.forEach((order: any) => {
                _parseOrder(order, order_information, algoVerseConfig)
            })

            let position_information: TPositionInformation[] = []
            if (securitiesAccount.positions) {
                securitiesAccount.positions?.forEach((p: any) => {
                    let invested = p.averagePrice * p.longQuantity;
                    position_information.push({
                        quantity: p.longQuantity,
                        long_quantity: p.longQuantity,
                        symbol: p.instrument.symbol,
                        average_price: p.averagePrice,
                        current_day_pl: p.currentDayProfitLoss,
                        current_day_pl_percentage: p.currentDayProfitLossPercentage,
                        ltp: p.marketValue,
                        invested: invested,
                        short_quantity: p.shortQuantity,
                        market_value: p.marketValue,
                        long_profit_loss: p.longOpenProfitLoss,
                        long_profit_loss_percentage: p.longOpenProfitLoss / invested * 100,
                        raw_data: p
                    })
                })
            }
            return {
                account_type: 'SCHWAB',
                account_name: 'Schwab Account',
                account_number: CACHE_SCHWAP_DATA.account_number,
                account_hash: account_hash,
                initial_balance: initial_balance,
                current_balance: current_balance,
                order_information: order_information,
                // position_information: position_information, // depricated
                position_summary: buildPositionSummary(algoVerseConfig, order_information, quote),
                portfolio_summary: buildPortfolioSummary(algoVerseConfig, order_information, quote),
                algoVerseConfig: algoVerseConfig
            }

        } catch (e: any) {
            dlog.ex(e)
            dlog.d(e.stack)
            throw new Error("Not able to parse the data" + e.message + e.stack)
        }
    }


    async function _getLiveQuote(symbol: string, token: string): Promise<TLiveQuote> {
        if (!quoteDataCache[symbol]) {
            await getLiveQuote(symbol, token)
        }
        return quoteDataCache[symbol]
    }

    async function _getLiveQuotes(symbol: string[], token: string): Promise<TLiveQuoteMap> {
        let result: TLiveQuoteMap = {}
        for (let i = 0; i < symbol.length; i++) {
            let x = symbol[i]
            result[x] = await _getLiveQuote(x, token)
        }
        return result
    }

    export function buildPortfolioSummary(algoVerseConfig: TAlgoVerseAccountConfig, orders: TOrderInformation[], quote: TLiveQuoteMap): TPortfolioSummary {
        let position = buildPositionSummary(algoVerseConfig, orders, quote);
        let initial_equity = algoVerseConfig.account_capital || 40000;//securitiesAccount.initialBalances.equity
        let total_invest = position.reduce((a, e) => a + e.position_avg * e.position_count, 0)
        let total_pl = position.reduce((a, e) => a + e.unrealized_pl, 0)
        let total_hold_cash = initial_equity - total_invest;
        let current_value = total_invest + total_hold_cash + total_pl;
        let realized_pl = position.reduce((p, e) => p + e.realized_pl, 0)

        let portfolio_summary: TPortfolioSummary = {
            initial_amount: initial_equity,
            current_amount: current_value,
            total_return: current_value - initial_equity,
            total_return_str: `${(current_value - initial_equity).toFixed(2)} (${((current_value - initial_equity) / initial_equity * 100).toFixed(2)}%)`,
            invested_amount: total_invest,
            cash_amount: total_hold_cash,
            realized_pl: realized_pl,
            interested_symbol: [{ symbol: 'AAPL', quantity: 0 }],
        }
        return portfolio_summary;
    }

    export function buildPositionSummary(algoVerseConfig: TAlgoVerseAccountConfig, orders: TOrderInformation[], quote: TLiveQuoteMap): TPositionSummary[] {
        let result: TPositionSummary[] = []
        let symbols = new Set<string>()
        // filter all order based on today.
        orders = orders.filter(x => x.status == 'FILLED' && x.ignore_order_for_tracking != true && x.fill_price > 0 && x.quantity > 0);

        // Group the orders by symbol
        const groupedOrders: TOrderInformation[][] = orders.reduce((acc: any, order) => {
            const { symbol } = order;
            if (!acc[symbol]) {
                acc[symbol] = [];
            }
            acc[symbol].push(order);
            return acc;
        }, {});

        Object.values(groupedOrders).forEach((orders: TOrderInformation[]) => {
            try {
                let position_count = 0;
                let position_avg = 0;
                let realized_pl = 0;
                let unrealized_pl = 0;
                let buy_count = 0;
                let sell_count = 0;
                let ltp: number = quote[orders[0].symbol]?.mark_price || 0
                orders.reverse().forEach(function (order) {
                    if (order.action == 'BUY') {
                        position_avg = ((position_avg * position_count) + (order.quantity * order.fill_price)) / (position_count + order.quantity)
                        position_count = position_count + order.quantity;
                        buy_count++;
                    } else {
                        realized_pl += order.quantity * (order.fill_price - position_avg)
                        position_count = position_count - order.quantity;
                        sell_count++;
                    }
                });
                unrealized_pl = position_count * (ltp - position_avg)
                result.push({
                    position_count: position_count,
                    position_avg: position_avg,
                    realized_pl: realized_pl,
                    unrealized_pl: unrealized_pl,
                    buy_sell_summary: `${buy_count}/${sell_count}`,
                    symbol: orders[0].symbol
                })

                symbols.add(orders[0].symbol)
            } catch (e: any) {
                dlog.ex(e)
            }
        })

        // all watch must be added in position
        algoVerseConfig.watch_list?.forEach(x => {
            if (symbols.has(x)) {
                return
            }
            symbols.add(x)
            result.push({
                symbol: x,
                position_count: 0,
                position_avg: 0,
                realized_pl: 0,
                unrealized_pl: 0,
                buy_sell_summary: ""
            })
        })
        return result;
    }

    // this will give the live quotes
    export async function getLiveQuote(symbols: string, auth_token_: string): Promise<TLiveQuoteMap> {
        try {
            let resp = await dnetwork.getSimpleStore(`${simpleStoreEndpoint}/api/utils/schwab/quote?auth_token_=${auth_token_}&symbols=${symbols}`)
            let result: TLiveQuoteMap = {}
            Object.values(resp.out).forEach((o: any) => {
                let data: TLiveQuote = {
                    symbol: o.symbol,
                    ask_price: o.quote.askPrice,
                    bid_price: o.quote.bidPrice,
                    ask_size: o.quote.askSize,
                    bid_size: o.quote.bidSize,
                    mark_price: o.quote.mark,
                    open: o.quote.openPrice,
                    high: o.quote.highPrice,
                    close: o.quote.closePrice,
                    low: o.quote.lowPrice,
                    quote_time: o.quote.quoteTime,
                    netChange: o.quote.netChange,
                    netPercentChange: o.quote.netPercentChange,
                    postMarketChange: o.quote.postMarketChange,
                    postMarketPercentChange: o.quote.postMarketPercentChange
                }
                result[o.symbol] = data
                quoteDataCache[o.symbol] = data
            })
            return result
        }
        catch (e: any) {
            dlog.ex(e);
            return {}
        }
    }


    // Given a list of order information - adjust by sell in week and day wize
    export type TDateOverride = {
        [key: string]: string // order_id to override_date
    }
    export type TOrderOrganizedDataByDay = {
        [day: string]: TOrderInformation[];
    }

    export type TOrderOrganizedDataByWeek = {
        [week: string]: TOrderOrganizedDataByDay
    }
    export function organizeOrderDataByWeekAndDay(data1: TOrderInformation[]): TOrderOrganizedDataByWeek {

        // make sure data is copied
        let data = JSON.parse(JSON.stringify(data1)) as TOrderInformation[]

        const organizedData: TOrderOrganizedDataByWeek = {};
        data.forEach(item => {
            if (item.ignore_order_for_tracking) {
                return;
            }
            const date = new Date(item.tracker_time || '');
            const weekNumber = _getWeekNumber(date);
            const dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase();
            const weekKey = `week${weekNumber}`;
            if (!organizedData[weekKey]) {
                organizedData[weekKey] = {
                    monday: [],
                    tuesday: [],
                    wednesday: [],
                    thursday: [],
                    friday: [],
                    saturday: [],
                    sunday: [],
                };
            }
            organizedData[weekKey][dayOfWeek].push(item);
        });
        return organizedData;
    }


    // Placing simple buy and sell order in market and limit
    export async function submitSimpleOrder(auth_token_: string, payload: TSimpleOrderPayload): Promise<TObject> {
        (payload as any).qty = payload.quantity
        if (payload.order_type == 'LIMIT') {
            if (Number.isNaN(payload.limit_price)) {
                throw new Error("You must have limit price for limit order")
            }

            if (payload.action == 'BUY' && payload.limit_price!! > quoteDataCache[payload.ticker].mark_price) {
                throw new Error("Please assign limit less than " + quoteDataCache[payload.ticker].mark_price)
            }

            if (payload.action == 'SELL' && payload.limit_price!! < quoteDataCache[payload.ticker].mark_price) {
                throw new Error("Please assign limit more than " + quoteDataCache[payload.ticker].mark_price)
            }
        }
        let res = await dnetwork.postSimpleStore(simpleStoreEndpoint + '/api/utils/schwab/submit_order', { auth_token_: auth_token_, order_payload: payload })
        return res;
    }

    // Placing simple buy and sell order in market and limit
    export async function submitOcoOrder(auth_token_: string, payload: TOcoOrderPayload): Promise<TObject> {
        return await dnetwork.postSimpleStore(simpleStoreEndpoint + '/api/utils/schwab/submit_oco_order', { auth_token_: auth_token_, order_payload: payload })
    }

    export async function submitRawOrder(auth_token_: string, payload: TObject): Promise<TObject> {
        return await dnetwork.postSimpleStore(simpleStoreEndpoint + '/api/utils/schwab/submit_raw_order', { auth_token_: auth_token_, order_payload: payload })
    }


    export async function submitOtoOrder(auth_token_: string, payload: TOtoOrderPayload): Promise<TObject> {
        return await dnetwork.postSimpleStore(simpleStoreEndpoint + '/api/utils/schwab/submit_oto_order', { auth_token_: auth_token_, order_payload: payload })
    }


    export async function submitOttOrder(auth_token_: string, payload: TOttOrderPayload): Promise<TObject> {
        return await dnetwork.postSimpleStore(simpleStoreEndpoint + '/api/utils/schwab/submit_ott_order', { auth_token_: auth_token_, order_payload: payload })
    }

    // cancel an order
    export async function cancelOrder(auth_token_: string, payload: { order_id: string, account_hash: string }) {
        return await dnetwork.postSimpleStore(`${simpleStoreEndpoint}/api/utils/schwab/cancel_order`, { auth_token_: auth_token_, ...payload })
    }


    // Helper function to get the ISO week number
    function _getWeekNumber(date: Date): number {
        const tempDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
        const dayNumber = (tempDate.getUTCDay() + 6) % 7 + 1; // Adjusting to ISO 8601, where Monday is 1 and Sunday is 7
        tempDate.setUTCDate(tempDate.getUTCDate() + 4 - dayNumber);
        const yearStart = new Date(Date.UTC(tempDate.getUTCFullYear(), 0, 1));
        return Math.ceil((((tempDate.getTime() - yearStart.getTime()) / 86400000) + 1) / 7);
    }

    function _getFillPrice(or: any) {
        try {
            return or?.orderActivityCollection[0].executionLegs[0]?.price
        } catch (e) {
            return 0
        }
    }

    function _buildDate(dateStr?: string | null) {
        if (!dateStr) {
            return null
        }
        return new Date(dateStr.split('/').reverse().join('-')).toISOString()
    }
}