import { dcolors, TObjCalBack } from "corexxx";
import { DButton } from "./DButton";
import { DCol } from "./DCol";
import { DRow } from "./DRow";
import { DText } from "./DText";

export function DListOfTitleSubtitleButton({ data, btn }: { data: { title: string, subtitle: string, extra: any }[], btn: { text: string, onClick: TObjCalBack } }) {
    return <DCol>
        {data.map((x, idx) => {
            return <DRow center key={idx} style={{ paddingTop: 10, paddingBottom: 10, flex: 0, borderBottom: '1px solid #f1f1f1' }}>
                <DCol>
                    <DText style={{ marginBottom: 5 }}>
                        {x.title}
                    </DText>
                    <DText style={{ fontSize: 13, color: dcolors.pallets.grey500 }}>
                        {x.subtitle}
                    </DText>
                </DCol>
                <DButton text={btn.text} onClick={() => btn.onClick(x.extra)} style={{ flex: 0 }} />
            </DRow>
        })}
    </DCol>
}