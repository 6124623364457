import { dassert, dlog, dnetwork } from "corexxx";
import { TAppConfig } from "../dweb/DAppCommand";
import { DWebTS } from "./DWebTS";

export class SimpleStoreClient {
    private static instance: SimpleStoreClient;
    private config?: TAppConfig; // You can replace 'any' with a more specific type for your configuration

    private constructor() {
        // Private constructor to prevent external instantiation
    }

    public static Get(): SimpleStoreClient {
        if (!SimpleStoreClient.instance) {
            SimpleStoreClient.instance = new SimpleStoreClient();
        }
        return SimpleStoreClient.instance;
    }

    public init(config: TAppConfig): void {
        this.config = config;
        dlog.d("SimpleStoreClint init successfully")
    }

    private assertInit(): void {
        dassert.verifyNotNullAndUndef(this.config, "Please call init first")
    }
    // Port a crash
    public reportCrash(msg: string, stack: string) {
        if (DWebTS.__DEV__) {
            dlog.e("Ignore reporting crash as debug env");
            return;
        }
        if (!stack || stack.trim().length == 0) {
            dlog.e("Ignore reporting crash as missing stack");
            return;
        }
        // stack should be cleaned before sending to server
        let raw_stack = stack
        stack = this.replaceStackTrace(stack).split("\n").map(x => x.trim()).slice(0, 6).join('\n')
        try {
            dnetwork.post('https://simplestore.dipankar.co.in/api/crash_log/report_crash', { app_name: this.config?.app_id, message: msg, stack: stack, raw_stack: raw_stack })
        } catch (e) {
            // pass
        }
    }
    private replaceStackTrace(inputString: string) {
        const regex = /\(http:\/\/localhost:3000\/static\/js\/bundle\.js:\d+:\d+\)/g;
        return inputString.replace(regex, '');
    }

}