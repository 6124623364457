import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { TStringCallback } from "corexxx";
import { CSSProperties } from 'react';

export function DSelectInput({ label, value, options, onChange, style }: { style?: CSSProperties, label?: string, value: string, options: { value: string, text: string }[], onChange: TStringCallback }) {
    return (
        <FormControl variant="outlined" style={{ width: 100, ...style }}>
            {label ? < InputLabel id="simple-select-l?abel">{label}</InputLabel> : null
            }
            <Select
                size="small"
                labelId="simple-select-label"
                id="simple-select"
                value={value}
                onChange={e => onChange(e.target.value)}
                label="Age"
            >
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                {options.map((y, idy) => <MenuItem key={idy} value={y.value}>{y.text}</MenuItem>)}
            </Select>
        </FormControl >
    );
}