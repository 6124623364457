import { DateTimePicker, DesktopDatePicker, LoadingButton, LocalizationProvider, TimePicker } from "@mui/lab";
import { Alert, Button, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Select, Step, StepLabel, Stepper, Switch, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TObject, TVoidCalBack } from "corexxx";
import React, { CSSProperties, useState } from "react";
import { DWebTS } from "../common_ts/DWebTS";
import { DAbsoluteDiv } from "./DAbsoluteDiv";
import { DCol } from "./DCol";
import { DRow } from "./DRow";
import { DSpace } from "./DSpace";
import { DText } from "./DText";


/*********************************************************
 * Define Forms
 *
 *********************************************************/
export type DFormSupportedType =
    | "date"
    | "time"
    | "datetime"
    | "input"
    | "textarea"
    | "radio"
    | "checkbox"
    | "switch"
    | "hidden"
    | "number"
    | "password"
    | "select"
    | "file"
    | 'files'
    | "image";
export type TOption = {
    key: string;
    value: any;
    text: string;
};

// Define Forms
export type DFormData = {
    name: string;
    label?: string;
    type: DFormSupportedType;
    subtype?: "password" | "email" | undefined;
    default?: any;
    required?: boolean;
    value?: any;
    disabled?: boolean;
    placeholder?: string;
    options?: TOption[];
    style?: CSSProperties;
};

export type DFormStepData = {
    label: string;
    data: Array<DFormData>;
}[];

export type TFormDataModel = { data?: DFormData[]; stepData?: DFormStepData };

function isMissing(x: any) {
    if (x == null || x == undefined) {
        return true;
    }
    if (x.length == 0) {
        return true;
    }
    return false;
}

export const DForm = React.memo(
    ({
        model,
        prefill,
        onSubmit,
        onCancel,
        config,
    }: {
        model: TFormDataModel;
        prefill?: TObject;
        onSubmit: (data: TObject) => void;
        onCancel?: TVoidCalBack;
        config?: TFormConfig;
    }) => {
        let [count, setCount] = useState(0)
        function getAllField(): DFormData[] {
            if (model.data) {
                return model.data;
            }
            if (model.stepData) {
                let result: DFormData[] = [];
                model.stepData.forEach((x) => {
                    x.data.forEach((y) => {
                        result.push(y);
                    });
                });
                return result;
            }
            return [];
        }

        // populate value
        React.useEffect(() => {
            let result1: TObject = {};
            let map: { [key: string]: DFormData } = {};

            getAllField().forEach((x: DFormData) => {
                result1[x.name] = "";
                if (!isMissing(prefill?.[x.name])) {
                    result1[x.name] = prefill?.[x.name];
                }

                if (!isMissing(x.value)) {
                    result1[x.name] = x.value;
                }
                if (!isMissing(x.default)) {
                    result1[x.name] = x.default;
                }

                map[x.name] = x;

                // fixTypeIn Inplace:
                if (map[x.name].type == "date") {
                    // Do Nothing as working expected
                }
            });
            setResult(result1);
            setModel1(map);
        }, [model.data, model.stepData, count, prefill]);

        let [disableEdit1, setDisableEdit1] = React.useState(config?.disableEdit || false)

        let [model1, setModel1] = React.useState<{ [key: string]: DFormData }>({});
        let [result, setResult] = React.useState<TObject>({});
        // mainly used for files
        let [loading, setLoading] = React.useState(false);
        let [error, setError] = React.useState("");
        const [activeStep, setActiveStep] = React.useState(0);

        const setData = (key: string, value?: any) => {
            let l: TObject = {};
            l[key] = value;
            setResult({ ...result, ...l });
        };

        const handleSubmit = async () => {
            if (!validateData()) {
                return;
            }
            setLoading(true);

            try {
                await onSubmit(fixTypeOut(result));
                // this will clear the form
                if (!config?.noClearOnSubmit) {
                    setCount(count + 1)
                }
            } catch (e: any) {
                setError(e.message);
            }
            setLoading(false);
        };

        const check = (key: string) => {
            if (model1[key].required == true && (result[key] == undefined || result[key].length == 0)) {
                throw Error(`Missing ${model1[key].name}. Please fill this`);
            }
        };

        // make sure all data is valid.
        const validateData = () => {
            try {
                getAllField().forEach((x) => {
                    check(x.name);
                });
                return true;
            } catch (e: any) {
                setError(e.message);
                return false;
            }
        };

        // This will fix the types like example number type should be a number
        const fixTypeOut = (obj: TObject) => {
            let newObj = DWebTS.deepCopy(obj);
            Object.keys(newObj).map((key) => {
                if (model1[key].type == "number") {
                    newObj[key] = parseFloat(newObj[key]);
                }
                if (model1[key].type == "date") {
                    // Ensure this is IOS format - Verified manullay
                    newObj[key] = newObj[key];
                }
            });
            return newObj;
        };

        // Make sure what is filled up in result is valid,
        const validatePartialData = () => {
            try {
                model.stepData?.[activeStep].data.forEach((x) => {
                    check(x.name);
                });
                return true;
            } catch (e: any) {
                setError(e.message);
                return false;
            }
        };

        function renderSwitch(data: DFormData) {
            switch (data.type) {
                default:
                case "input":
                    return (
                        <TextField
                            required={data.required}
                            disabled={data.disabled}
                            placeholder={data.placeholder}
                            label={data.label || data.name.toUpperCase()}
                            variant="outlined"
                            value={result[data.name]}
                            onChange={(e) => setData(data.name, e.target.value)}
                        />
                    );
                case "hidden":
                    return (
                        <TextField
                            required={data.required}
                            disabled={data.disabled}
                            placeholder={data.placeholder}
                            label={data.label || data.name.toUpperCase()}
                            variant="outlined"
                            value={result[data.name]}
                            onChange={(e) => setData(data.name, e.target.value)}
                        />
                    );
                case "number":
                    return (
                        <TextField
                            placeholder={data.placeholder}
                            required={data.required}
                            disabled={data.disabled}
                            type="number"
                            label={data.label || data.name.toUpperCase()}
                            variant="outlined"
                            value={result[data.name]}
                            onChange={(e) => setData(data.name, e.target.value)}
                        />
                    );
                case "password":
                    return (
                        <TextField
                            placeholder={data.placeholder}
                            required={data.required}
                            disabled={data.disabled}
                            type="password"
                            label={data.label || data.name.toUpperCase()}
                            variant="outlined"
                            value={result[data.name]}
                            onChange={(e) => setData(data.name, e.target.value)}
                        />
                    );
                case "textarea":
                    return (
                        <TextField
                            placeholder={data.placeholder}
                            required={data.required}
                            disabled={data.disabled}
                            label={data.label || data.name.toUpperCase()}
                            variant="outlined"
                            minRows={4}
                            multiline={true}
                            value={result[data.name]}
                            onChange={(e) => setData(data.name, e.target.value)}
                        />
                    );
                case "date":
                    return (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                                readOnly={data.disabled}
                                label={data.label || data.name.toUpperCase()}
                                inputFormat="DD/MM/YYYY"
                                value={result[data.name]}
                                onChange={(value: any) => {
                                    setData(data.name, value);
                                }}
                                renderInput={(params: any) => (
                                    <TextField {...params} required={data.required} disabled={data.disabled} placeholder={data.placeholder} />
                                )}
                            />
                        </LocalizationProvider>
                    );
                case "time":
                    return (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                                readOnly={data.disabled}
                                label={data.label || data.name.toUpperCase()}
                                value={result[data.name]}
                                onChange={(value: any) => {
                                    setData(data.name, value);
                                }}
                                renderInput={(params: any) => (
                                    <TextField {...params} required={data.required} disabled={data.disabled} placeholder={data.placeholder} />
                                )}
                            />
                        </LocalizationProvider>
                    );
                case "datetime":
                    return (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                                readOnly={data.disabled}
                                label={data.label || data.name.toUpperCase()}
                                value={result[data.name]}
                                onChange={(value: any) => {
                                    setData(data.name, value);
                                }}
                                renderInput={(params: any) => (
                                    <TextField {...params} required={data.required} disabled={data.disabled} placeholder={data.placeholder} />
                                )}
                            />
                        </LocalizationProvider>
                    );
                case "select":
                    return (
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">{data.label || data.name.toUpperCase()}</InputLabel>
                            <Select
                                placeholder={data.placeholder}
                                required={data.required}
                                disabled={data.disabled}
                                label={data.label || data.name.toUpperCase()}
                                value={result[data.name] || ""}
                                onChange={(e) => setData(data.name, e.target.value)}
                            >
                                {data.options?.map((op) => (
                                    <MenuItem key={op.key} value={op.value}>
                                        {op.text}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    );
                case "switch":
                    return (
                        <FormControlLabel
                            disabled={data.disabled}
                            control={
                                <Switch
                                    placeholder={data.placeholder}
                                    required={data.required}
                                    //label={data.label || data.name.toUpperCase()}
                                    value={result[data.name] || false}
                                    onChange={(e: any) => setData(data.name, e.target.checked)}
                                    disabled={data.disabled}
                                />
                            }
                            label={data.label || data.name.toUpperCase()}
                        />
                    );
                case "image":
                    return (
                        <TextField
                            placeholder={data.placeholder}
                            required={data.required}
                            disabled={data.disabled}
                            type="file"
                            label={data.label || data.name.toUpperCase()}
                            variant="outlined"
                            onChange={(e) => setData(data.name, (e.target as HTMLInputElement).files?.[0])}
                        />
                    );
                case "file":
                    return (
                        <TextField
                            placeholder={data.placeholder}
                            required={data.required}
                            disabled={data.disabled}
                            type="file"
                            label={data.label || data.name.toUpperCase()}
                            variant="outlined"
                            onChange={(e) => setData(data.name, (e.target as HTMLInputElement).files?.[0])}
                        />
                    );
                case "files":
                    return (
                        <TextField
                            placeholder={data.placeholder}
                            required={data.required}
                            disabled={data.disabled}
                            type="file"
                            label={data.label || data.name.toUpperCase()}
                            variant="outlined"
                            inputProps={{
                                multiple: true
                            }}
                            onChange={(e) => {
                                let test = (e.target as HTMLInputElement).files as any
                                let files = []
                                for (let i = 0; i < test.length; i++) {
                                    files.push(test[i])
                                }
                                setData(data.name, files)
                            }}
                        />
                    );
            }
        }

        return (
            <DCol>
                <DRow style={{ marginBottom: 20 }}>
                    <DText mode="title" style={{ flex: 1 }}>{config?.title || 'Form'}</DText>
                    {disableEdit1 ? <Button variant="outlined" onClick={() => setDisableEdit1(false)}>Edit</Button> : null}
                </DRow>
                {error && error.trim().length > 0 ? (
                    <Alert severity="error" style={{ marginBottom: 20 }}>
                        {error}
                    </Alert>
                ) : null}
                {model.data ? (
                    <>
                        <div style={{ position: 'relative' }}>
                            <DRow style={{ flexWrap: "wrap" }}>
                                {model.data.map((x, idx) => {
                                    // normal Form
                                    return (
                                        <DCol
                                            key={idx}
                                            style={{ marginBottom: 20, flex: "none", width: "100%", ...x.style, display: x.type == "hidden" ? "none" : "flex" }}
                                        >
                                            {renderSwitch(x)}
                                        </DCol>
                                    );
                                })}

                            </DRow>
                            {disableEdit1 ? <DAbsoluteDiv /> : null}
                        </div>
                        {!disableEdit1 ? (<DRow style={{ marginTop: 30, flex: "none", width: "100%" }}>
                            <DSpace />
                            <Button onClick={() => { setDisableEdit1(true); onCancel?.() }} style={{ marginRight: 20 }}>
                                Cancel
                            </Button>
                            <LoadingButton onClick={handleSubmit} variant="contained" loading={loading}>
                                {config?.trigger_btn_text || " Submit"}
                            </LoadingButton>
                        </DRow>) : null}
                    </>
                ) : null}

                {model.stepData ? (
                    <>
                        <Stepper alternativeLabel activeStep={activeStep} style={{ marginBottom: 20 }}>
                            {model.stepData.map((x) => (
                                <Step key={x.label}>
                                    <StepLabel>{x.label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        {model.stepData.map((x, idx) => (
                            <DCol style={{ display: activeStep === idx ? "flex" : "none" }}>
                                <DRow style={{ flexWrap: "wrap" }}>
                                    {x.data.map((y) => (
                                        <DCol
                                            key={y.name}
                                            style={{ marginBottom: 20, flex: "none", width: "100%", ...y.style, display: y.type == "hidden" ? "none" : "flex" }}
                                        >
                                            {renderSwitch(y)}
                                        </DCol>
                                    ))}
                                </DRow>
                            </DCol>
                        ))}
                        <DRow>
                            {activeStep > 0 ? (
                                <Button onClick={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)} variant="contained">
                                    BACK
                                </Button>
                            ) : null}
                            <DSpace />
                            {activeStep < model.stepData.length - 1 ? (
                                <Button onClick={() => (validatePartialData() ? setActiveStep((prevActiveStep) => prevActiveStep + 1) : null)} variant="contained">
                                    Next
                                </Button>
                            ) : null}
                            {activeStep == model.stepData.length - 1 ? (
                                <Button onClick={() => { onCancel?.() }} style={{ marginRight: 10 }}>
                                    Cancel
                                </Button>
                            ) : null}
                            {activeStep == model.stepData.length - 1 ? (
                                <Button onClick={handleSubmit} variant="contained">
                                    Submit
                                </Button>
                            ) : null}
                        </DRow>
                    </>
                ) : null}
            </DCol>
        );
    }
);

export type TFormConfig = {
    title: string; // dialog title
    trigger_btn_text?: string;
    triggerStyle?: CSSProperties;
    triggerIcon?: React.ReactNode;
    customTrigger?: React.ReactNode;
    noClearOnSubmit?: boolean; // by defaukt it will clear the inpit
    submit_btn_text?: boolean;
    disableEdit?: boolean;// first show in read only mode.
};

export const DFormDialog = React.memo(
    ({
        model,
        prefill,
        onSubmit,
        onCancel,
        dialogConfig,
        visible,
    }: {
        model: TFormDataModel;
        prefill?: TObject;
        onSubmit: (data: TObject, pendingResult?: TObject) => void;
        onCancel?: TVoidCalBack;
        dialogConfig: TFormConfig;
        visible?: boolean;
    }) => {

        React.useEffect(() => {
            setOpen(visible || false)
        }, [visible])

        const [open, setOpen] = React.useState(visible || false);
        const handleClose = () => {
            setOpen(false);
            onCancel?.();
        };
        return (
            <div
                onClick={(event) => {
                    event.stopPropagation();
                }}
            >
                {/* This for the trigger */}
                {dialogConfig.customTrigger ? <span onClick={() => setOpen(true)}>{dialogConfig.customTrigger}</span> : null}
                {dialogConfig.trigger_btn_text ? (
                    <Button size="small" onClick={() => setOpen(true)} style={{ ...dialogConfig.triggerStyle, flex: 0 }} startIcon={dialogConfig.triggerIcon} variant="contained" >
                        {dialogConfig.trigger_btn_text}
                    </Button>
                ) : null}
                {dialogConfig.triggerIcon ? (
                    <IconButton onClick={() => setOpen(true)} style={dialogConfig.triggerStyle}>
                        {dialogConfig.triggerIcon}
                    </IconButton>
                ) : null}

                <Dialog
                    fullWidth={true}
                    open={open}
                    onClose={handleClose}
                    //scroll={scroll}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <DialogTitle id="scroll-dialog-title">{dialogConfig.title}</DialogTitle>
                    <DialogContent dividers={true}>
                        <DForm
                            model={model}
                            prefill={prefill}
                            onSubmit={async (data) => {
                                await onSubmit(data);
                                handleClose();
                            }}
                            onCancel={() => {
                                onCancel?.();
                                handleClose();
                            }}
                            config={dialogConfig}
                        />
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
);