import { CSSProperties } from "react";

export const DCodeDisplay = ({ code, style }: { code: string, style?: CSSProperties }) => {
    return (
        <div style={style}>
            <pre style={{ width: '100%', height: '100%' }}>
                <code>
                    {code}
                </code>
            </pre>
        </div>
    );
};