import { DResponsiveWindow } from "../../dweb/DResponsiveWindow";
import { DTextEmptyView } from "../../dweb/DTextEmptyView";

export const DefaultPageInProgress = () => {
    return (
        <DResponsiveWindow>
            <DTextEmptyView style={{ margin: 40, maxWidth: 100, flex: 1, alignSelf: 'center' }}
                icon={<img src={require("../../img/error.png")}></img>}
                title="This feature is under construction!"
                subtitle="Hold on! We are working on this feature. Please revisit this page after sometime."
            />
        </DResponsiveWindow>
    );
};