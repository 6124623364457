import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { AppBar, Avatar, Button, IconButton, Menu, MenuItem, Toolbar, Typography } from "@mui/material";
import React, { CSSProperties } from "react";
import { useDAppCommand } from "./DAppCommand";
import { DNestedMenu } from "./DNestedMenu";
import { TDPageRootLayoutConfig } from "./DPageRootLayout";
import { DSpace } from "./DSpace";

export const DNavigation = ({ config, leftElement, leftOffset }: { config: TDPageRootLayoutConfig; leftElement?: any; leftOffset?: number }) => {
    const appCommand = useDAppCommand();
    //const courseCommand = useCourse();
    const [anchorEl, setAnchorEl] = React.useState<any>(null);
    const handleMenuClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    let style: CSSProperties = { backgroundColor: appCommand.getAppConfig()?.primary_color!!, ...config.appBarStyle };
    if (config.appBarTrasparent) {
        style = { background: "transparent", boxShadow: "none", color: "black", ...style };
    }
    return (
        <AppBar style={{ position: "fixed", left: leftOffset || 0, maxWidth: `calc( 100% - ${leftOffset || 0}px)`, ...style }}>
            <Toolbar variant="dense" style={{ alignItems: "center", display: "flex" }}>
                {leftElement}
                {config.logo ? (
                    <a href="/">
                        <img src={config.logo} style={{ width: 23, height: 23, marginRight: 10 }} />
                    </a>
                ) : null}
                {config.icon ? <a href="/">{config.icon}</a> : null}
                <Typography variant="h6" component="div" sx={{ ...config.appBarTextStyle, marginRight: 5 }} >
                    <a href={config.title_path} style={{ textDecoration: 'none', color: 'inherit' }}>{config.title}</a>
                </Typography>

                {config.topNavigation
                    ? config.topNavigation.map((x, idx) => {
                        if ((x.child?.length || 0) > 0) {
                            return <DNestedMenu menu={x} key={idx} />;
                        }
                        return <Button href={x.path} key={idx} style={{ color: 'white', textTransform: 'none' }}>{x.title}</Button>;
                    })
                    : null}
                <DSpace />
                {config.rightActions?.map((x, idx) => {
                    if (x.showAfterLogin && !appCommand.accountState) {
                        return null;
                    }
                    return x.text ? (
                        <Button onClick={x.onClick} key={idx} style={{ textTransform: "capitalize", ...config.appBarTextStyle }} href={x?.href}>
                            {x.text}
                        </Button>
                    ) : (
                        <IconButton key={idx} size="large" aria-label="search" color="inherit" onClick={x.onClick}>
                            {x.icon}
                        </IconButton>
                    );
                })}

                {appCommand.accountState ? (
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        {config.appBarConfig?.appBarRightJSX || null}
                        <IconButton
                            size="large"
                            onClick={(event) => {
                                setAnchorEl(event.currentTarget);
                            }}
                            href={config.settingNavList ? "" : "/account"}
                            color="inherit"
                        >
                            <Avatar sx={{ width: 24, height: 24 }} alt={appCommand.accountState.name} src={appCommand.accountState.image} />
                            {config.settingNavList ? <KeyboardArrowDownIcon style={{ marginLeft: 10 }} /> : null}
                        </IconButton>
                        {config.settingNavList ? (
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                open={Boolean(anchorEl)}
                                onClose={() => {
                                    setAnchorEl(null);
                                }}
                            >
                                {config.settingNavList?.map((k, idk) => {
                                    return (
                                        <MenuItem
                                            key={idk}
                                            onClick={() => {
                                                appCommand.api.navigate(k.link);
                                            }}
                                        >
                                            {k.text}
                                        </MenuItem>
                                    );
                                })}
                            </Menu>
                        ) : null}
                    </div>
                ) : (
                    <Button style={{ textTransform: "capitalize", ...config.appBarTextStyle }} href={"/account"}>
                        Login
                    </Button>
                )}
            </Toolbar>
        </AppBar>
    );
};
