import { RefreshOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { TObject, dcolors, dnetwork } from "corexxx";
import React from "react";
import { DWebTS } from "../common_ts/DWebTS";
import { useDAppCommand } from "./DAppCommand";
import { DCol } from "./DCol";
import { DFormDialog, TFormDataModel } from "./DForm";
import { DRow } from "./DRow";
import { DSpace } from "./DSpace";
import { DTable, DTableColumn } from "./DTable";
import { DText } from "./DText";

// you should eb using memo
export const DSimpleStoreModel = ({
    endpoint,
    model,
    tableViewColumns,
    config
}: {
    endpoint: string;
    model: TFormDataModel;
    tableViewColumns: DTableColumn[];
    config?: {
        search_key?: string,
        title?: string
    }
}) => {
    const appCommand = useDAppCommand()
    const [result, setResult] = React.useState<TObject[]>([]);
    const [query, setQuery] = React.useState("");
    DWebTS.useEffectAsync(async () => {
        reload();
    }, []);

    const _call = async (func: any) => {
        appCommand.api.setNotification({ type: 'info', msg: 'Loading...' })
        try {
            await func();
            appCommand.api.setNotification({ type: 'success', msg: 'success' })
        } catch (e) {
            appCommand.api.setNotification({ type: 'error', msg: 'error' })
        }
    }

    const _actions = {
        onCreate: async (obj: TObject) => {
            _call(async () => {
                await dnetwork.postSimpleStore(endpoint + '/create', obj);
                await reload();
            })
        },
        onDelete: async (_id: string) => {
            _call(async () => {
                await dnetwork.postSimpleStore(endpoint + '/delete', { _id: _id });
                await reload();
            })
        },
        onSearch: async (query: TObject) => {
            _call(async () => {
                let data = await dnetwork.postSimpleStore(endpoint + '/find', query);
                setResult(data.out)
            })
        },
        onUpdate: async (_id: string, obj: TObject) => {
            _call(async () => {
                await dnetwork.postSimpleStore(endpoint + '/update', { ...obj, _id: _id });
                await reload();
            })
        },
    };

    const reload = () => {
        if (query.length > 0) {
            let q: TObject = {}
            q[config?.search_key || 'name'] = query
            _actions?.onSearch?.(q);
        } else {
            _actions?.onSearch?.({});
        }
    }

    return (
        <DCol>
            <DRow style={{ paddingTop: 20, paddingBottom: 20, alignItems: 'center' }}>
                <DText style={{ textTransform: 'uppercase', color: dcolors.pallets.grey900, fontSize: 20 }}>{config?.title || 'SimpleStore'}</DText>
                <DSpace />
                <DFormDialog
                    model={model}
                    onSubmit={async (obj, pendingObj) => {
                        await _actions.onCreate?.(obj);
                        await reload();
                    }}
                    onCancel={() => { }}
                    dialogConfig={{ title: config?.title || "Create new " + (config?.title || "entry"), trigger_btn_text: "New" }}
                />
                <IconButton onClick={reload}><RefreshOutlined /></IconButton>
            </DRow>
            <DTable data={result} columns={tableViewColumns} actions={[
                {
                    text: 'delete', onClick: (obj) => _actions.onDelete(obj._id)
                },
                {
                    text: 'update', onClick: (obj) => _actions.onDelete(obj._id)
                }
            ]} />
        </DCol>
    );
};
