import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Button, IconButton, Menu, MenuItem } from "@mui/material";
import { TVoidCalBack } from "corexxx";
import React, { CSSProperties } from "react";

export const DDropDownMenu = ({ style, actions, icon, groupButton }: { style?: CSSProperties, groupButton?: boolean, icon?: JSX.Element; actions: { text: string; onClick: TVoidCalBack }[] }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        event.preventDefault();
        event.stopPropagation();
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            {groupButton ? (<Button
                style={{ height: 36, borderRadius: 0 }}
                color="primary"
                size="small"
                variant="contained"
                aria-label="select option"
                aria-controls={open ? "split-button-menu" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                ▼
            </Button>) : (<IconButton

                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                {icon || <MoreVertIcon />}
            </IconButton>)}

            <Menu
                id="long-menu"
                MenuListProps={{
                    "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {actions.map((option) => (
                    <MenuItem
                        key={option.text}
                        onClick={(e) => {
                            option.onClick();
                            handleClose();
                            e.stopPropagation();
                        }}
                    >
                        {option.text}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};