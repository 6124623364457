import { useEffect, useRef, useState } from 'react';
import { DButton } from './DButton';
import { DCodeDisplay } from './DCodeDisplay';
import { DCol } from './DCol';
import { DSearchButton } from './DSearchButton';
import { DText } from './DText';
import { DTextAreaButton } from './DTextAreaButton';

export function DWebsocketConsole() {
    const [url, setUrl] = useState('');
    const [message, setMessage] = useState('');
    const [log, setLog] = useState<string[]>([]);
    const [isConnected, setIsConnected] = useState(false);
    const websocketRef = useRef<WebSocket | null>(null);

    const connectWebSocket = () => {
        if (websocketRef.current) {
            websocketRef.current?.close();
        }

        websocketRef.current = new WebSocket(url);

        websocketRef.current.onopen = () => {
            setIsConnected(true);
            setLog(prevLog => [...prevLog, 'Connected']);
        };

        websocketRef.current.onmessage = (event) => {
            setLog(prevLog => [...prevLog, `Received: ${event.data}`]);
        };

        websocketRef.current.onclose = () => {
            setIsConnected(false);
            setLog(prevLog => [...prevLog, 'Disconnected']);
        };

        websocketRef.current.onerror = (error: any) => {
            setLog(prevLog => [...prevLog, `Error: ${error.message}`]);
        };
    };

    const disconnectWebSocket = () => {
        if (websocketRef.current) {
            websocketRef.current.close();
        }
    };

    const sendMessage = () => {
        if (websocketRef.current && isConnected) {
            setLog(prevLog => [...prevLog, `Sent: ${message}`]);
            websocketRef.current.send(JSON.stringify(JSON.parse(message)));
        }
    };

    useEffect(() => {
        return () => {
            if (websocketRef.current) {
                websocketRef.current.close();
            }
        };
    }, []);

    return (
        <DCol className="App">

            <DCol style={{ flex: 0 }}>
                <DText>WebSocket Client connected:{isConnected ? 'true' : 'false'}</DText>
                <DSearchButton
                    style={{ marginTop: 20 }}
                    inputStyle={{ fontFamily: 'monospace' }}
                    value={url}

                    onTextChange={(text) => setUrl(text)}
                    onBtnClick={isConnected ? disconnectWebSocket : connectWebSocket}
                    placeholder="WebSocket URL"
                    btn_text={isConnected ? 'Disconnect' : 'Connect'}
                />
                <DTextAreaButton
                    inputStyle={{ fontFamily: 'monospace' }}
                    style={{ marginTop: 20 }}
                    value={message}
                    onTextChange={(text) => setMessage(text)}
                    placeholder="Message"
                    onBtnClick={sendMessage}
                    btn_text={'Send Message'}
                    extra_btn={<DButton text="clear" onClick={() => setLog([])}></DButton>}
                />
                <DText>Logs (count: {log.length})</DText>
            </DCol>

            <DCodeDisplay style={{ height: `calc(${window.innerHeight - 350}px)`, overflowY: 'scroll', border: '1px solid black', marginTop: 20 }} code={
                log.join("\n-----------------------\n")
            } />
        </DCol>
    );
}
