
import { ArrowBack } from "@mui/icons-material";
import { IconButton } from '@mui/material';
import { dcolors, TObject, TVoidCalBack } from "corexxx";
import React, { CSSProperties, useState } from "react";
import { useDAppCommand } from "../../libs/dweb/DAppCommand";
import { DCol } from "../../libs/dweb/DCol";
import { DIllustrationHelper } from "../../libs/dweb/DIllustrationHelper";
import { DRow } from "../../libs/dweb/DRow";
import { DSelectInput } from "../../libs/dweb/DSelectInput";
import { DSpace } from "../../libs/dweb/DSpace";
import { DTable } from '../../libs/dweb/DTable';
import { DText } from "../../libs/dweb/DText";
import { DTextTwoKeyValue } from "../../libs/dweb/DTextRawKeyValue";
import { SSAlgoTradingDef } from "../../libs/imported/SSAlgoTradingDef";
import { useAlgoVerse } from "../Hook";


export function OrderTable({ orders, status1 }: { orders: SSAlgoTradingDef.TOrderInformation[], status1?: string }) {
    let [status, setStatus] = useState<string>(status1 || 'ALL')
    let hook = useAlgoVerse();
    let appCommand = useDAppCommand()
    React.useEffect(() => {
        if (!status1) {
            setStatus(status1 || 'ALL')
        }
    }, [status1])

    async function cancelOrder(order_id: string) {
        hook.api.cancelOrder(order_id);
    }

    const _IgoreOrder = async (order_id: string) => {
        await appCommand.api.safeCall(async () => {
            await SSAlgoTradingDef.updateAlgoVerseAccountConfig(appCommand.accountState?.auth_token_!!, hook.state.BrokerAccountInfo?.algoVerseConfig?._id!!, {
                ignore_order_list: [order_id, ...hook.state.BrokerAccountInfo?.algoVerseConfig?.ignore_order_list!!]
            });
        }, "update ignore list", "not able to update ignore list")
        await hook.api.loadBrokerAccountInfo()
    }

    const _override_date = async (order_id: string) => {
        await appCommand.api.showPrompt("dd/mm/yyyy", async (f) =>
            await appCommand.api.safeCall(
                async () => {
                    let data: TObject = {}
                    data['order_date_override.' + order_id] = f
                    await SSAlgoTradingDef.updateAlgoVerseAccountConfig(appCommand.accountState?.auth_token_!!, hook.state.BrokerAccountInfo?.algoVerseConfig?._id!!, data);
                },
                "Trying to override data",
                "Not able to override date"
            )
        )
        await hook.api.loadBrokerAccountInfo()
    }

    if (!hook.state.BrokerAccountInfo) {
        return <DIllustrationHelper title="Noting is realy to show" subtitle="Please wait while we are loading...." image={undefined} />
    }

    return (<DCol>
        <DTextWithControl
            style={{ padding: 10 }}
            text="Order History"
            rightJsx={
                <DSelectInput style={{ width: 200 }} label="status" options={[
                    { text: 'WORKING', value: 'WORKING' },
                    { text: 'FILLED', value: 'FILLED' },
                    { text: 'CANCELED', value: 'CANCELED' },
                    { text: 'PENDING_ACTIVATION', value: 'PENDING_ACTIVATION' },
                    { text: 'ALL', value: 'ALL' },
                    { text: 'REJECTED', value: 'REJECTED' },
                ]} value={status} onChange={setStatus} />
            } />

        <DTable
            style={{ border: 0, boxShadow: 'none' }}
            data={status == 'ALL' ? orders : orders.filter(x => !status || (x.status == status))}
            columns={[
                { field: 'order_id' },
                { field: 'symbol' },
                { field: 'order_category' },
                { field: 'quantity' },
                { field: 'limit_price', headerName: 'Limit price' },
                { field: 'fill_price', format: (arg, obj) => obj.fill_price == 0 ? '-' : obj.fill_price?.toFixed(2), },
                { field: 'stop_price', format: (arg, obj) => obj.stop_price == 0 ? '-' : obj.stop_price?.toFixed(2), },
                { field: 'entered_time', format: (arg) => new Date(arg).toLocaleTimeString() },
                { field: 'closed_time', format: (arg) => new Date(arg).toLocaleTimeString() },
                {
                    field: 'status',
                    formatEx: (arg, obj) => {
                        return <DText title={obj.statusDescription}>{obj.status}</DText>;
                    }
                },
            ]}
            actions={[
                {
                    text: 'cancel', onClick: (obj) => cancelOrder(obj.order_id)
                }, {
                    text: 'ignore', onClick: (obj) => _IgoreOrder(obj.order_id)
                }, {
                    text: 'override_date', onClick: (obj) => _override_date(obj.order_id)
                }
            ]}
        /></DCol>)
}




export function DTextWithControl({ backBtnCallback, text, rightJsx, style, dark }: { backBtnCallback?: TVoidCalBack, text: string, rightJsx?: any, style?: CSSProperties, dark?: boolean }) {
    return (<DRow style={{
        flex: 0,
        alignItems: 'center',
        ...style,

    }}>
        {backBtnCallback ? <IconButton style={{ marginRight: 20 }} onClick={backBtnCallback}><ArrowBack /></IconButton> : null}
        <DText dark={dark} style={{ fontSize: 20, fontWeight: 'bolder' }}>{text}</DText>
        <DSpace />
        {rightJsx}
    </DRow >)
}


export function PositionTable() {
    let hook = useAlgoVerse();
    let appCommand = useDAppCommand()

    async function exitOrder(position: SSAlgoTradingDef.TPositionInformation) {
        //await appCommand.api.postSimpleStore(`/api/utils/schwab/cancel_order`, { order_id: order_id, account_hash: hook.state.BrokerAccountInfo?.account_info.account_number_hash })
        await hook.api.loadBrokerAccountInfo()
    }

    if (!hook.state.BrokerAccountInfo) {
        return <DText>Still loading???</DText>
    }
    let pos = hook.state.BrokerAccountInfo.position_summary[0]

    return (<DCol>
        <DTextWithControl
            style={{ padding: 10 }}
            text="Your Positions"
        />
        <DTextTwoKeyValue
            style={{ paddingLeft: 10, marginBottom: 20 }}
            items={[
                { key: 'Buy/Sell', value: pos.buy_sell_summary },
                //{ key: 'Totals P/L', value: `${pos.calculate_total_pl.toFixed(2)}(${pos.calculate_total_pl_percentage.toFixed(2)}%)`, style: { background: getColor(pos.calculate_total_pl) } },
                { key: 'Realized P/L', value: `${pos.realized_pl.toFixed(2)}` },
                { key: 'Unrealized P/L', value: pos.unrealized_pl.toFixed(2) },
                { key: 'Position Count', value: pos.position_count.toFixed(2) },
                { key: 'Average', value: pos.position_avg.toFixed(2) },
            ]} />
        <DTable
            style={{ border: 0, boxShadow: 'none', display: 'none' }}
            data={hook.state.BrokerAccountInfo.position_summary}
            columns={[
                { field: 'symbol' },
                { field: 'quantity', format: (arg, obj) => `${obj.short_quantity ? '-' + obj.short_quantity + '(short)' : '+' + obj.long_quantity + '(long)'}` },
                { field: 'invested', view_type: 'two_decimal' },
                { field: 'average_price', view_type: 'two_decimal' },
                {
                    headerName: 'Total P/L',
                    field: 'long_profit_loss',
                    format: (arg, obj) => `${obj.long_profit_loss.toFixed(2)} (${obj.long_profit_loss_percentage.toFixed(2)}%)`,
                    styleFn: (arg, obj) => ({ color: obj.long_profit_loss > 0 ? 'green' : 'red' })
                },
            ]}
            actions={[
                {
                    text: 'exit on Market', onClick: (obj) => exitOrder(obj.order_id)
                }
            ]}
        /></DCol>)
}

function getColor(num: number): string {
    return num < 0 ? dcolors.pallets.red500 : dcolors.pallets.green600
}