import { PhotoCamera } from "@mui/icons-material";
import { Avatar, IconButton } from "@mui/material";
import { TAnyCallback, dcolors } from "corexxx";
import { CSSProperties } from "react";

// export Avatar with Exit Pictire Button
export const DAvatarWithChangePicture = ({
    src,
    alt,
    sx,
    onChange,
}: {
    src?: string;
    onChange?: (files: File) => any;
    sx?: any;
    alt?: string | undefined;
}) => {
    return (
        <div style={{ position: "relative" }}>
            <Avatar src={src} alt={alt} sx={sx} />
            {onChange ? (
                <DUploadButton
                    style={{ position: "absolute", bottom: -10, right: -10, color: dcolors.pallets.black }}
                    onChange={(obj: any) => {
                        onChange(obj[0] as File);
                    }}
                />
            ) : null}
        </div>
    );
};

export const DUploadButton = ({ onChange, style }: { onChange: TAnyCallback; style?: CSSProperties }) => {
    return (
        <IconButton color="primary" aria-label="upload picture" component="label" style={style}>
            <input
                hidden
                accept="image/*"
                type="file"
                onChange={(e: any) => {
                    onChange(e.target.files);
                }}
            />
            <PhotoCamera />
        </IconButton>
    );
};
