import { Button } from "@mui/material"
import { TVoidCalBack } from "corexxx"
import { CSSProperties } from "react"
import { useDAppCommand } from "./DAppCommand"
import { DImage } from "./DImage"
import { DPopUpMenu } from "./DPopUpMenu"
import { DRow } from "./DRow"
import { DSpace } from "./DSpace"

// Mostly used by single app just add this header to the top.
export function PageHeaderMenu({ style, logo, rightDropDownActions, logoStyle }: { logoStyle?: CSSProperties, style?: CSSProperties, logo: any, rightDropDownActions?: { text: string, href?: string, onClick?: TVoidCalBack }[] }) {
    let appCommand = useDAppCommand()
    return <DRow style={{ alignItems: 'center', zIndex: 1, color: 'white', background: appCommand.getAppConfig()?.primary_color, paddingTop: 5, paddingBottom: 5, paddingLeft: 10, paddingRight: 10, position: 'fixed', top: 0, right: 0, left: 0, ...style }}>
        <a href={"/"}>
            <DImage src={logo} style={{ maxHeight: 20, ...logoStyle }} />
        </a>
        <DSpace />
        {rightDropDownActions ? (appCommand.accountState?._id ? (
            <DPopUpMenu btnStyle={{ color: 'white' }} btnText={`Welcome ${appCommand.accountState.name}`} actions={[...rightDropDownActions,
            { text: 'Account Settings', href: '/account' },
            { text: 'Log Out', onClick: () => { appCommand.logout() } }
            ]}></DPopUpMenu>
        ) : (
            <DRow style={{ flex: 1, justifyContent: 'flex-end' }}>
                <Button style={{ color: 'white' }} size="small" href="/account" variant="contained">Sign Up/ Sign In</Button>
            </DRow>
        )) : null}
    </DRow>
}